import React, { useState, useEffect } from "react";
import { addCommas, cropString } from "@arema/components/Util";
import { Button, Groupper, Header, Table } from 'react-frontier';
import { useTitle } from "@arema/components/Hooks";
import classNames from "classnames";
import moment from "moment";
import API from "../../API";
import '../../style/catalog.scss';
import '../../style/order_list.scss';

interface SearchOrder{
	order_hash: string,
	date: string,
	pdv_id: number,
	event_name: string,
	first_name: string,
	last_name: string,
	email: string,
	cancelled: boolean,
	paid: boolean,
	expired: boolean,
	total: number,
}

var OrderList = ()=>{
	var [recentOrders, setRecentOrders] = useState<SearchOrder[]>(null);
	var [searchOrders, setSearchOrders] = useState<SearchOrder[]>(null);
	var [searching, setSearching] = useState<boolean>(false);
	var [searchQuery, setSearchQuery] = useState<string>('');
	var [searchedQuery, setSearchedQuery] = useState<string>(null);
	var [ordersError, setOrdersError] = useState<string>(null);
	var { setTitle } = useTitle();

	useEffect(()=>{
		if(!recentOrders){
			API.getRecentOrders().then(res=>{
				if(res.error) return setOrdersError(res.message);
				setRecentOrders(res.data);
			}).catch(err=>{
				return setOrdersError('Hubo un error inesperado cargando las ordenes recientes.');
			});
		}
		setTitle('Ordenes')
	}, [recentOrders]);

	var onInputKey = (ev: React.KeyboardEvent<HTMLInputElement>)=>{
		if(ev.key!=='Enter') return;
		search();
	}

	var onSearchChange = (ev: { target: { value: string } })=>{
		setSearchQuery(ev.target.value);
		if(searchOrders!==null && ev.target.value.length==0){
			setSearchedQuery(null);
			setSearchOrders(null);
		}
	}
	
	var search = ()=>{
		if(searching || searchQuery.length<=3) return;
		setSearching(true);
		setOrdersError(null);
		setSearchedQuery(searchQuery);
		API.searchOrders(searchQuery).then(res=>{
			if(res.error) return setOrdersError(res.message);
			setSearchOrders(res.data);
		}).catch(err=>{
			setOrdersError('Hubo un error buscando las ordenes. (LCL-1)');
		}).finally(()=>{
			setSearching(false);
		})
	}

	// var showFilters = ()=>{

	// }


	return <div>
		<Groupper title="Ordenes" width={1000} fitted style={{ paddingBottom: 0, overflow: 'hidden' }}>
			<div className="fr input action" style={{ padding: 15 }}>
				<input type="text" placeholder="Buscar orden..." value={searchQuery} onChange={onSearchChange} onKeyUp={onInputKey} />
				<Button text="Buscar" onClick={search} />
			</div>
			<div style={{ overflowX: 'auto' }}>
				<table className="fr orders selectable top table">
					<thead>
						<tr>
							<th className="collapsing">ID</th>
							<th className="collapsing">Fecha</th>
							<th className="mobile">Estatus</th>
							<th className="desktop">Nombre</th>
							<th>Correo</th>
							<th className="collapsing desktop">Estatus</th>
							<th className="collapsing">Total</th>
						</tr>
					</thead>
					<tbody>
						{ordersError ? (
							<tr className="noselect">
								<td colSpan={7}>
									<Header text="Error" subtext={ordersError} containerStyle={{ padding: 15, margin: '50px 0' }} />
								</td>
							</tr>
						) : searching ? (
							<tr className="noselect">
								<td colSpan={7}>
									<Header loading text="Buscando ordenes..." containerStyle={{ margin: '50px 0' }} />
								</td>
							</tr>
						) : (recentOrders || searchOrders) ? (
							(searchOrders || recentOrders).length==0 ? (
								<tr className="noselect">
									<td colSpan={7} className="normal empty">
										<Header text="No se encontraron ordenes" subtext={searchedQuery ? `Buscando: ${searchedQuery}` : null} size="small" containerStyle={{ margin: '20px 0' }} />
									</td>
								</tr>
							) : (searchOrders || recentOrders).map(a=>{
								var Label = <div className={classNames('fr label', {
									red: a.cancelled,
									transparent: !a.cancelled && a.expired && !a.paid,
									green: !a.cancelled && a.paid,
									blue: !a.cancelled && !a.paid && !a.expired,
								})}>
									{a.cancelled ? 'Cancelado' : (a.expired ? 'Expirado' : (a.paid ? 'Pagado' : 'Pendiente'))}
								</div>
								return (
									<Table.Row key={`ord-${a.order_hash}`} href={`/orders/${a.order_hash}`}>
										<Table.Cell collapsing>{a.order_hash}</Table.Cell>
										<Table.Cell collapsing>{moment.unix(parseInt(a.date)).format('DD/MM/YY HH:mm')}</Table.Cell>
										<Table.Cell collapsing className="mobile">
											{Label}
										</Table.Cell>
										<Table.Cell className="desktop">
											{(!a.email || a.email.length==0) ? (
												<i className="minus grey icon"></i>
											) : a.pdv_id && (!a.email || a.email.length==0) ? (
												'Venta PDV'
											) : (
												cropString(`${a.first_name} ${a.last_name}`, 20, true)
											)}
										</Table.Cell>
										<Table.Cell>
											{a.email}
										</Table.Cell>
										<Table.Cell collapsing className="desktop">
											{Label}
										</Table.Cell>
										<Table.Cell>
											{addCommas(a.total)}
										</Table.Cell>
									</Table.Row>
								)
							})
						) : (
							<tr className="noselect">
								<td colSpan={7}>
									<Header loading text="Cargando ordenes..." containerStyle={{ margin: '50px 0' }} />
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</Groupper>
	</div>
}

export default OrderList;