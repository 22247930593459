import React, { useState, useEffect } from 'react'
import { Button, Field, Groupper, Header, Input, Message } from "react-frontier";
import { UserExternal, CatalogEvent, Promoter, SetLoading } from '@arema/components/Classes';
import { Checkbox, Dropdown, Modal } from 'semantic-ui-react';
import { useNavigate, useParams } from "react-router-dom";
import { bindClose, bindFormChange } from '@arema/components/Util';
import { useTitle } from '@arema/components/Hooks';
import { ErrorHeader, NotFound } from '../../components';
import { useUser } from '../../AdminHooks';
import Validator from "@arema/components/Validator";
import API from "./../../API";
import SearchEventModal from "../../components/SearchEventModal";
import SearchPromoterModal from "../../components/SearchPromoterModal";
import UserExternalAcccessModal from "../../components/UserExternalAcccessModal";
import Toast from "react-hot-toast";
import UserAccess from '../../UserAccess';

import './../../style/scanner.scss';

enum PASSWORD_MODE {
	AUTOGENERATED = 0,
	PROVIDED = 1,
}

enum MODAL {
	SEARCH_EVENT = 100,
	SEARCH_PROMOTER = 200,

	USER_UPDATE_PASSWORD = 300,
	USER_EXTERNAL_ACCESS = 400,
}

interface UserForm {
	first_name: string,
	last_name: string,
	email: string,
	access: boolean,
	all_events: boolean,
}

var UserExternalCreate = () => {
	var { external_id } = useParams();
	const navigate = useNavigate()
	var { setTitle } = useTitle();
	var IS_CREATE = external_id === 'create';
	var [selectedEvents, setSelectedEvents] = useState<CatalogEvent[]>([]);
	var [selectedEvent, setSelectedEvent] = useState<CatalogEvent>(null);
	var [selectedPromoters, setSelectedPromoters] = useState<Promoter[]>([]);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [formData, setFormData] = useState<UserForm>(null)
	var { hasAccess } = useUser();
	var canChangePassword = hasAccess(UserAccess.CATALOGS.EXTERNAL_CHANGE_PASSWORD);
	var [passwordForm, setPasswordForm] = useState<{ mode: PASSWORD_MODE, password: string }>({ mode: PASSWORD_MODE.AUTOGENERATED, password: '' });
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [shownModal, setShownModal] = useState<MODAL>(MODAL.USER_EXTERNAL_ACCESS);


	var onSaveEvents = (event: CatalogEvent): boolean => {
		if (!selectedEvents.some(ev => ev.event_id === event.event_id)) {
			var newEvents = [...selectedEvents];
			newEvents.push(event);
			setSelectedEvents(newEvents);
			return true;
		}
		Toast.error('El evento ya fue seleccionado previamente');
		return false;
	}

	var onSavePromoter = (promoter: Promoter): boolean => {
		if (!selectedPromoters.some(p => p.promoter_id === promoter.promoter_id)) {
			var newPromoters = [...selectedPromoters];
			newPromoters.push(promoter);
			setSelectedPromoters(newPromoters);
			return true;
		}
		Toast.error('El promotor ya fue seleccionado previamente');
		return false;
	}

	useEffect(() => {
		var id = parseInt(external_id);
		if (!IS_CREATE && !Number.isNaN(id)) {
			if (formData === null) {
				loadData(id);
			}
		} else {
			setTitle('Crear Nuevo Empresario');
			setFormData({
				first_name: '',
				last_name: '',
				email: '',
				access: true,
				all_events: false,
			})
		}
	}, []);

	var loadData = async (external_id: number) => {
		var res = await API.getUserExternal(external_id)
		if (!res.error) {
			setFormData({
				...res.data,
				access: !!res.data.access,
				all_events: !!res.data.all_events,
			});
			setTitle(`Empresario ${res.data.first_name} ${res.data.last_name}`)
			if (res.data.events) {
				setSelectedEvents(res.data.events);
			}
			if (res.data.promoters) {
				setSelectedPromoters(res.data.promoters);
			}
		} else {
			setLoadError('Hubo un error cargando el empresario (LCL-3)');
		}
	}

	var deleteEvents = (event_id: number) => {
		return () => {
			var newEvents = [...selectedEvents].filter(sev => sev.event_id !== event_id);
			setSelectedEvents(newEvents);
		}
	}

	var eventAcces = (event: CatalogEvent) => {
		return () => {
			setShownModal(MODAL.USER_EXTERNAL_ACCESS);
			setSelectedEvent(event);
		}
	}

	var deletePromoter = (promoter_id: number) => {
		return () => {
			var newPromoters = [...selectedPromoters].filter(sep => sep.promoter_id !== promoter_id);
			setSelectedPromoters(newPromoters);
		}
	}

	var submit = async (setLoading: (b: boolean) => void) => {
		var { valid, prompts } = Validator(formData, {
			first_name: [{ rule: 'length', params: [3, 32], label: 'Nombre' }],
			last_name: [{ rule: 'length', params: [3, 32], label: 'Apellido' }],
			email: [{ rule: "email", label: 'Correo electrónico' }],
		});
		setErrorPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		var saveUser: UserExternal = {
			external_id: Number(external_id),
			first_name: formData.first_name,
			last_name: formData.last_name,
			email: formData.email,
			all_events: formData.all_events,
			access: formData.access,
		}
		if (IS_CREATE) {
			API.createUserExternal(saveUser, selectedEvents.map(a => a.event_id), selectedPromoters.map(a => a.promoter_id)).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se creó el usuario');
				navigate(`/c/partners/${res.data.external_id}`);
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperadoal crear el usuario (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editUserExternal(saveUser, selectedEvents.map(a => a.event_id), selectedPromoters.map(a => a.promoter_id)).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se actualizo el usuario');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado actualizando el usuario (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	var sendPasswordChange = (setLoading: SetLoading) => {
		var { valid, prompts } = Validator(passwordForm, {
			mode: ['number'],
			password: passwordForm.mode === PASSWORD_MODE.AUTOGENERATED ? [] : [
				{ rule: 'password' }
			]
		});
		setModalPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		API.changeUserExternalPassword(parseInt(external_id), passwordForm.mode === PASSWORD_MODE.AUTOGENERATED, passwordForm.password).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha cambiado la contraseña del usuario y se le ha enviado por correo.');
			setPasswordForm({ password: '', mode:PASSWORD_MODE.AUTOGENERATED });
			setShownModal(null);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado cambiando la contraseña (LCL-1)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var changePassword = () => {
		if (!canChangePassword) return;
		setPasswordForm({
			mode: PASSWORD_MODE.AUTOGENERATED,
			password: ''
		});
		setShownModal(MODAL.USER_UPDATE_PASSWORD);
	}

	if (!IS_CREATE && Number.isNaN(parseInt(external_id))) {
		return <NotFound />
	}
	if (loadError) {
		return <ErrorHeader error={loadError} />
	}
	if (!formData) {
		return <Header loading text='Cargando Usuario' />
	}

	var onFormChange = bindFormChange(formData, setFormData);
	var onFormPassChange = bindFormChange(passwordForm, setPasswordForm);

	return (
		<div>
			<Groupper
				title={IS_CREATE ? 'Nuevo empresario' : 'Editar empresario'}
				width={600}>
				<Field amount={2}>
					<Input
						label="Nombre"
						value={formData.first_name}
						onChange={onFormChange('first_name')}
					/>
					<Input
						label="Apellido"
						value={formData.last_name}
						onChange={onFormChange('last_name')}
					/>
				</Field>
				<Input
					label="Correo"
					value={formData.email}
					onChange={onFormChange('email')}
				/>
				<Field style={{ marginTop: 15 }}>
					<Checkbox
						toggle
						label={'Acceso'}
						onChange={onFormChange('access', true)}
						checked={formData.access}
					/>
				</Field>
				<Field>
					<Checkbox
						toggle
						label={'Este usuario puede ver cualquier evento.'}
						onChange={onFormChange('all_events', true)}
						checked={formData.all_events}
					/>
				</Field>

				{IS_CREATE && (
					<Message type='info' text='La contraseña del usuario se le enviará a el correo electrónico que se ingresó.' style={{ marginTop: 15 }} />
				)}

				{!formData.all_events && (
					<table className="fr fitted first table" style={{ maxWidth: 600, marginTop: 15 }}>
						<thead>
							<tr>
								<th className='title' colSpan={3}>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										Eventos
										<Button text='Agregar' color='black' size='tiny' iconName='plus-circle' onClick={bindClose(setShownModal, MODAL.SEARCH_EVENT)} />
									</div>
								</th>
							</tr>
							<tr>
								<th className="collapsing">ID</th>
								<th>Nombre</th>
								<th className='centered collapsing'><i className="remove icon"></i></th>
							</tr>
						</thead>
						<tbody>
							{selectedEvents.length === 0 ? (
								<tr className="normal">
									<td colSpan={7} className="empty">No hay eventos asignados a este usuario</td>
								</tr>
							) : (
								selectedEvents.map(e => (
									<tr key={`sevt-${e.event_id}`}>
										<td className="collapsing">{e.event_id}</td>
										<td>{e.event_name}</td>
										<td className='collapsing'>
											<Dropdown
												icon={null}
												trigger={(
													<Button icon iconName="ellipsis-h" size="tiny" />
												)}
											>
												<Dropdown.Menu>
													<Dropdown.Item
														icon='remove'
														text='Eliminar'
														onClick={deleteEvents(e.event_id)}
													/>
													<Dropdown.Item
														icon='lock'
														text='Permisos'
														onClick={eventAcces(e)}
													/>
												</Dropdown.Menu>
											</Dropdown>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				)}

				{!formData.all_events && (
					<table className="fr fitted first table" style={{ maxWidth: 600, marginTop: 15 }}>
						<thead>
							<tr>
								<th className='title' colSpan={3}>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										Promotores
										<Button text='Agregar' color='black' size='tiny' iconName='plus-circle' onClick={bindClose(setShownModal, MODAL.SEARCH_PROMOTER)} />
									</div>
								</th>
							</tr>
							<tr>
								<th className="collapsing">ID</th>
								<th>Promotor</th>
								<th className='centered collapsing'><i className="remove icon"></i></th>
							</tr>
						</thead>
						<tbody>
							{selectedPromoters.length === 0 ? (
								<tr className="normal">
									<td colSpan={7} className="empty">No hay promotores asignados a este usuario</td>
								</tr>
							) : (
								selectedPromoters.map(p => (
									<tr key={`sprt-${p.promoter_id}`}>
										<td className="collapsing">{p.promoter_id}</td>
										<td>{p.promoter_name}</td>
										<td className='collapsing'>
											<Button
												iconName='remove'
												color='black'
												size='tiny'
												style={{ minWidth: 40 }}
												onClick={deletePromoter(p.promoter_id)}
											/>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				)}

				<Message type='error' list={errorPrompts} style={{ marginTop: 15 }} />
				<div className="actions">
					{
						!IS_CREATE && canChangePassword ? <Button text="Cambiar contraseña" basic onClick={changePassword} /> : null
					}
					<Button text="Guardar" color="black" onClick={submit} />
				</div>
			</Groupper>

			{shownModal === MODAL.SEARCH_EVENT &&
				<SearchEventModal
					open={shownModal === MODAL.SEARCH_EVENT}
					onClose={bindClose(setShownModal)}
					title='Buscar Evento'
					onEventSelected={onSaveEvents}
				/>
			}

			{shownModal === MODAL.SEARCH_PROMOTER &&
				<SearchPromoterModal
					open={shownModal === MODAL.SEARCH_PROMOTER}
					onClose={bindClose(setShownModal)}
					title='Buscar Promotor'
					onPromoterSelected={onSavePromoter}
				/>
			}

			{(shownModal === MODAL.USER_EXTERNAL_ACCESS && selectedEvent && !IS_CREATE) &&
				<UserExternalAcccessModal
					title={'Editar permisos'}
					open={shownModal === MODAL.USER_EXTERNAL_ACCESS}
					onClose={bindClose(setShownModal)}
					external_id={parseInt(external_id)}
					event_id={selectedEvent.event_id}
				/>
			}

			{!!canChangePassword && (
				<Modal open={shownModal === MODAL.USER_UPDATE_PASSWORD} onClose={bindClose(setShownModal)} size="tiny">
					<Modal.Header>Cambiar contraseña</Modal.Header>
					<Modal.Content>
						<Header text="Cambiar contraseña de usuario" subtext="Se cambiará la contraseña del usuario y se le mandará un correo con la contraseña." />
						<Checkbox 
							toggle
							label="Autogenerar contraseña" 
							style={{ marginTop: 10 }} 
							checked={passwordForm.mode == PASSWORD_MODE.AUTOGENERATED} 
							onChange={() => { setPasswordForm({ password: '', mode: passwordForm.mode === PASSWORD_MODE.AUTOGENERATED ? PASSWORD_MODE.PROVIDED : PASSWORD_MODE.AUTOGENERATED }) }} />
						{passwordForm && passwordForm.mode == PASSWORD_MODE.PROVIDED ? (
							<Input label="Contraseña" style={{ marginTop: 10 }} value={passwordForm.password}  onChange={onFormPassChange('password')} />
						) : null}
						<Message text="El usuario deberá de ingresar a el administrador web para realizar un cambio final de contraseña." centered type="info" style={{ marginTop: 10 }} />
						<Message type="error" list={modalPrompts} style={{ marginTop: 10 }} />
					</Modal.Content>
					<Modal.Actions>
						<Button text="Cancelar" basic onClick={bindClose(setShownModal)} />
						<Button text="Guadar" color="black" onClick={sendPasswordChange} />
					</Modal.Actions>
				</Modal>
			)}
		</div>
	)
}

export default UserExternalCreate;
