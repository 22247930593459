import React, { useState, useEffect, useRef } from 'react'
import { Button, Field, Groupper, Header, Image, Input, Message, Table, Toolbar } from "react-frontier";
import { Scanner, CatalogEvent, SetLoading } from '@arema/components/Classes';
import { Checkbox, Icon, Modal } from 'semantic-ui-react';
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { bindChange, bindClose, bindFormChange } from '@arema/components/Util';
import { useTitle } from '@arema/components/Hooks';
import { DeletedBanner, ErrorHeader, NotFound, UserLog } from '../../components';
import Validator from "@arema/components/Validator";
import API from "./../../API";
import SearchEventModal from "../../components/SearchEventModal";
import Toast from "react-hot-toast";
import { useUser } from '../../AdminHooks';
import UserAccess from '../../UserAccess';

import './../../style/scanner.scss';

enum Modals{
	QRCODE = 1,
	REINITIALIZE = 2,
	RESET_TOKEN = 3,
}

var ScannerView = ()=>{
	var { hasAccess } =	useUser();
	var { scanner_id } = useParams();
	var { setTitle } = useTitle();
	var [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [notFound, setNotFound] = useState<boolean>(false);
	var [accessDate, setAccessDate] = useState<boolean>(false);
	var [scanner, setScanner] = useState<Scanner>(null);
	var [qrcode, setQrcode] = useState<string>(null);
	var [shownModal, setShownModal] = useState<Modals>(null);
	var [modalConfirm, setModalConfirm] = useState<boolean>(false);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var tokenInputRef = useRef<HTMLInputElement>(null);
	var navigate = useNavigate();
	const IS_CREATE = scanner_id==='create';
	const ACCCES = {
		REGEN_TOKEN: hasAccess(UserAccess.CATALOGS.SCANNER_REGEN_TOKEN),
		REINITIALIZE: hasAccess(UserAccess.CATALOGS.SCANNER_REINITIALIZE),
	}

	var onSaveEvents = (event: CatalogEvent): boolean=>{
		if(!scanner) return false;
		var ix = scanner.events.findIndex(a=>a.event_id===event.event_id);
		if(ix!==-1){
			Toast.error('Este checador ya tiene este evento agregado.');
			return false;
		}
		var scn = {...scanner};
		scn.events.push({
			event_id: event.event_id,
			event_name: event.event_name
		});
		setScanner(scn);
		return true;
	}

	useEffect(()=>{
		var id = parseInt(scanner_id);
		if(!isNaN(id)) {
			if(scanner===null || scanner.scanner_id!==id){
				setScanner(null);
				loadData(id);
			}
		} else {
			setTitle('Crear checador');
			setAccessDate(false);
			setScanner({
				scanner_id: null,
				scanner_name: '',
				date_start: null,
				date_end: null,
				all_events: false,
				all_areas: false,
				events: [],
				token: null
			})
		}
	}, [scanner_id]);

	var loadData = async (scanner_id: number)=>{
		API.getScanner(scanner_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			if(!res.data) return setNotFound(true);
			setTitle(`Checador ${res.data.scanner_name}`);
			setScanner(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando el checador (LCL-1)');
		});
	}

	var deleteEvents = (event_id: number)=>{
		return ()=>{
			var scn = {...scanner};
			scn.events = scn.events.filter(a=>a.event_id!==event_id);
			setScanner(scn);
		}
	}

	var submit = async (setLoading: (b: boolean)=>void)=>{
		var { valid, prompts } = Validator(scanner, {
			scanner_name: [{ rule: 'length', params: [3, 32], label: 'Nombre' }]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		var payload: Scanner = {
			scanner_id: scanner.scanner_id,
			scanner_name: scanner.scanner_name,
			date_start: accessDate ? scanner.date_start : null,
			date_end: accessDate ? scanner.date_end : null,
			all_events: scanner.all_events,
			all_areas: false,
		}
		var events = scanner.events.map(a=>a.event_id);
		if(scanner.scanner_id){
			API.editScanner(payload, events).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Se actualizo el checador');
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado actualizando el checador (LCL-10)']);
			}).finally(()=>{
				setLoading(false);
			});
		}else{
			API.createScanner(payload, events).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha creado el checador!');
				navigate(`/scanners/${res.data.scanner_id}`);
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado creando el checador (LCL-11)']);
			}).finally(()=>{
				setLoading(false);
			});
		}
	}
	
	var showQrcode = ()=>{
		setShownModal(Modals.QRCODE);
		if(!scanner?.scanner_id || !scanner.token) return;
		if(!qrcode){
			API.getScannerBarcode(scanner.scanner_id, scanner.token).then(res=>{
				if(res.error) return Toast.error(res.message, { duration: 10000 });
				setQrcode(res.data.qrcode);
			}).catch(err=>{
				return Toast.error('Hubo un error inesperado cargando el código QR del checador. (LCL-1)', { duration: 10000 })
			})
		}
	}

	var showModal = (modal: Modals)=>{
		return ()=>{
			setModalConfirm(false);
			setModalPrompts(null)
			setShownModal(modal);
		}
	}

	var sendReinitialize = (setLoading: SetLoading)=>{
		if(!modalConfirm || !scanner?.scanner_id || !ACCCES.REINITIALIZE) return;
		setLoading(true);
		API.reinitializeScanner(scanner.scanner_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha reinicializado el checador. Los dispositivos conectados a este token se han desconectado.', { duration: 10000 });
			setShownModal(null);
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado reinicializando el checador. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var sendNewToken = (setLoading: SetLoading)=>{
		if(!modalConfirm || !scanner?.scanner_id || ACCCES.REGEN_TOKEN) return;
		setLoading(true);
		API.resetScannerToken(scanner.scanner_id).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha generado el nuevo token para el checador. Los dispositivos conectados a este token se han desconectado.', { duration: 10000 });
			setShownModal(null);
			setScanner({
				...scanner,
				token: res.data.token
			});
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado generando el nuevo token para el checador. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		});
	}

	if((Number.isNaN(parseInt(scanner_id)) && !IS_CREATE) || notFound) {
		return <NotFound />
	}
	if(loadError) {
		return <ErrorHeader error={loadError} />
	}
	if(!scanner) {
		return <Header loading text='Cargando checador' />
	}

	var onFormChange = bindFormChange(scanner, setScanner);

	return (
		<div>
			{scanner.deleted ? <DeletedBanner date_deleted={scanner.date_deleted} /> : null}
			<Groupper
				title={`${IS_CREATE ? 'Crear' : 'Editar'} checador`}
				width={600}
				actions={(
					<Button text="Guardar" color="black" onClick={submit} />
				)}
			>
				{!IS_CREATE &&
					<Toolbar fitted>
						{
							ACCCES.REINITIALIZE &&
							<Toolbar.Item text='Reinicializar' icon='sync' onClick={showModal(Modals.REINITIALIZE)} />
						}
						{
							ACCCES.REGEN_TOKEN &&
							<Toolbar.Item text='Nuevo token' icon='code' onClick={showModal(Modals.RESET_TOKEN)} />
						}
					</Toolbar>
				}
				<Input label="Nombre" placeholder="Nombre del checador" value={scanner.scanner_name} onChange={onFormChange('scanner_name')} style={{ marginBottom: 15 }} />
				<Checkbox toggle label={'Este checador tiene fecha de acceso'} onChange={bindChange(setAccessDate, true)} checked={!!accessDate} style={{ marginBottom: 10 }} />
				{accessDate && (
					<Field amount={2} style={{ marginBottom: 15 }}>
						<Input label="Fecha Inicios" value={scanner.date_start} onChange={onFormChange('date_start')} calendar={{ date: scanner.date_start, mode: 'date' }} />
						<Input label="Fecha fin" value={scanner.date_end} onChange={onFormChange('date_end')} calendar={{ date: scanner.date_end, mode: 'date' }} />
					</Field>
				)}
				<Checkbox toggle label={'Este checador puede checar cualquier evento.'} onChange={onFormChange('all_events', true)} checked={!!scanner.all_events} />

				{!!scanner?.scanner_id && (
					<Field label='Token' style={{ marginTop: 15 }} labelStyle={{ textAlign: 'center' }}>
						<Input 
							readonly
							value={`${scanner.scanner_id}s${scanner.token}`} 
							ref={tokenInputRef} 
							inputStyle={{ fontSize: 32, padding: 5, textAlign: 'center', fontWeight: 'bold', fontFamily: 'Roboto Mono' }}
							labelStyle={{ textAlign: 'center' }}
							onClick={()=>{
								tokenInputRef.current?.select();
							}}
							button={(
								<Button icon iconName='qrcode' iconStyle={{ lineHeight: '46px', fontSize: 24 }} onClick={showQrcode} />
							)}
						/>
					</Field>
				)}

				{!scanner.all_events && (
					<Table
						fitted
						striped
						className='first'
						style={{ marginTop: 15 }}
						title='Eventos'
						collapsingIndexes={[0, 2]}
						centeredIndexes={[2]}
						headers={['ID', 'Nombre', <Icon name="remove" />]}
						emptyText='No hay eventos asignados a este checador'
						button={(
							<Button size='tiny' text='Agregar' iconName='plus-circle' color='black' onClick={bindClose(setOpenSearchModal, true)} />
						)}
						data={scanner.events.map(a=>([
							a.event_id,
							a.event_name,
							<Button
								iconName='remove'
								color='black'
								size='tiny'
								style={{ minWidth: 40 }}
								onClick={deleteEvents(a.event_id)}
							/>
						]))}
					/>
				)}
				<Message type='error' list={errorPrompts} style={{ marginTop: 15 }} />
			</Groupper>
			<Modal open={shownModal===Modals.QRCODE} size='tiny' onClose={bindClose(setShownModal)}>
				<Modal.Header>Token de registro</Modal.Header>
				<Modal.Content>
					<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 400 }}>
						{!qrcode ? (
							<Header loading text='Cargando código' />
						) : (
							<>
								<div style={{ marginBottom: 10, fontWeight: 'bold', maxWidth: 300, textAlign: 'center' }}>
									Escanea este código en la pantalla de registro del checador
								</div>
								<Image src={`${qrcode}?size=300`} style={{ width: 300, height: 300 }} />
								<div className='fr mono' style={{ fontSize: 28, fontWeight: 'bold', marginTop: 10 }}>
									{`${scanner.scanner_id}s${scanner.token}`}
								</div>
								<div style={{ marginTop: -5, color: 'gray' }}>
									Checador #{scanner.scanner_id}
								</div>
							</>
						)}
					</div>
				</Modal.Content>
				<Modal.Actions>
					<Button text='Cerrar' onClick={bindClose(setShownModal)} />
				</Modal.Actions>
			</Modal>
			{ACCCES.REINITIALIZE && (
				<Modal open={shownModal===Modals.REINITIALIZE} size='tiny' onClose={bindClose(setShownModal)}>
					<Modal.Header>Reinicializar checador</Modal.Header>
					<Modal.Content>
						<Header text='¿Reinicializar checador?' style={{ marginBottom: 20 }} />
						<Message type='info' centered>
							Esto desconectará cualquier dispositivo que se haya registrado con este token. Los dispositivos desconectados tendrán que registrarse con un token nuevamente.
						</Message>
						<Checkbox toggle checked={!!modalConfirm} onChange={bindChange(setModalConfirm, true)} label={'Deseo reinicializar el checador'} style={{ marginTop: 20 }} />
						<Message list={modalPrompts} type='error' />
					</Modal.Content>
					<Modal.Actions>
						<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
						<Button text='Continuar' color='black' disabled={!modalConfirm} onClick={sendReinitialize} />
					</Modal.Actions>
				</Modal>
			)}
			{ACCCES.REGEN_TOKEN && (
				<Modal open={shownModal===Modals.RESET_TOKEN} size='mini' onClose={bindClose(setShownModal)}>
					<Modal.Header>Nuevo token</Modal.Header>
					<Modal.Content>
						<Header text='¿Generar nuevo token?' style={{ marginBottom: 20 }} />
						<Message type='info' centered>
						Esto generará un nuevo token aleatorio para este checador. Los dispositivos que estén conectados a este token serán desconectados.
						</Message>
						<Checkbox toggle checked={!!modalConfirm} onChange={bindChange(setModalConfirm, true)} label={'Deseo generar un nuevo token para este checador'} style={{ marginTop: 20 }} />
						<Message list={modalPrompts} type='error' />
					</Modal.Content>
					<Modal.Actions>
						<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
						<Button text='Continuar' color='black' disabled={!modalConfirm} onClick={sendNewToken} />
					</Modal.Actions>
				</Modal>
			)}
			{!!scanner.scanner_id && (
				<UserLog user={scanner} />
			)}
			<SearchEventModal
				open={openSearchModal}
				onClose={bindClose(setOpenSearchModal)}
				title='Buscar Evento'
				onEventSelected={onSaveEvents}
			/>
		</div>
	)
}

export default ScannerView;
