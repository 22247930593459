import { EventData, EventDate, EventDateData, Venue, APIResponse, VenueSection, SeatStatus, DepositType, DeliveryMethod, Promoter, SearchEvent, Scanner, Coupon, OrderRefund, EventGroup, UserExternal, UserAdmin, SectionHold, Location, ShortUserAdmin, LoginSource } from '@arema/components/Classes';
import { AccountOwnerType, BankAccount, ConciliaDeposit, ParsedOrder, PaymentMethods, ListPayout, PapeletaData, ReportResponse } from './AdminClasses';
import { partition } from '@arema/components/Util';
import axios, { ResponseType } from 'axios';
import moment from 'moment';
import { PAGE_AUTH } from './AdminConfig';
const ROOT_URL = process.env.REACT_APP_API_URL
const DEBUG = process.env.REACT_APP_DEBUG;

export interface TokenData{ 
	access: string, 
	expires: number,
}

var CACHE : { [x: string]: APIResponse<any> } = {};

var getToken = () : TokenData=>{
	var jsonstr = localStorage.getItem('admin_token');
	if(!jsonstr) return null;
	try{
		var data = JSON.parse(jsonstr) as TokenData;
		if(!data) return null;
		if(!data.access) return null;
		var now = moment().unix();
		if(data.expires<=(now+30)) return null;
		return data;
	}catch(e){
		return null;
	}
}

var saveToken = (token_data: TokenData)=>{
	localStorage.setItem('admin_token', JSON.stringify(token_data));
	window.dispatchEvent(new Event('user_token'));
}

var logout = ()=>{
	localStorage.removeItem('admin_token');
	localStorage.removeItem('user_data');
	window.dispatchEvent(new Event('user_data'));
	window.dispatchEvent(new Event('user_logout'));
}

interface PostOptions{
	login?: boolean,
	response_type?: ResponseType,
	access_token?: string,
}

async function post<T=any>(endpoint: string, data?: any, opts: PostOptions={}) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'show-code': DEBUG ? 1 : 0,
			'token': '',
			'Content-Type': 'application/json'
		}
	}
	if(opts?.login!==false){
		if(opts?.access_token){
			options.headers.token = `1:::${opts.access_token}`;
		}else{
			var u = getToken();
			if(u===null){
				logout();
				return { error: true, code: 'PV-INVCRD', message: 'Las credenciales de accceso no son válidas (LCL-TKNCH-L).' }
			}
			options.headers.token = `1:::${u.access}`;
		}
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = `${ROOT_URL}/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, {
			...options,
			responseType: opts?.response_type || 'json'
		});
		if(DEBUG) console.log(` => Response: ${(res.headers['content-length']/1024).toFixed(2)} KB`);
		if(res.data && res.data.error && res.data.code=='P-TKNE'){
			logout();
		}
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		if((err as any)?.code==="ERR_NETWORK"){
			return {
				error: true, 
				message: 'No se pudo establecer una conexión con el servidor. (API-NONTWK)',
				code: 'API-NONTWK',
			}
		}
		return {
			error: true, 
			message: 'Hubo un error inesperado (API-LCL-1)',
			code: 'API-LCL-1',
		}
	}
};

var cachePost = async <T>(endpoint: string, key: string, data?: any) : Promise<APIResponse<T>>=>{
	if(CACHE[key]) return CACHE[key] as APIResponse<T>;
	var res = await post(endpoint, data);
	if(!res.error) CACHE[key] = res;
	return res;
}

var getAuthUrl = ()=>{
	return `${PAGE_AUTH}/admin?s=${LoginSource.ADMIN}&continue=${encodeURIComponent(`${window.location.origin}/auth`)}&redirect=${encodeURIComponent(window.location.href)}`
}

export interface UserLogin{
	user: {
		admin_id: number,
		username: string,
		email: string,
		first_name: string,
		last_name: string,
		superadmin: boolean,
		change_password: boolean,
	},
	access: number[]
}

var USER_LOGIN_CACHE : UserLogin = null;

var login = async (username: string, password: string)=>{
	var res = await post('login', { username, password, admin: 1 }, { login: false })
	if(!res.error && res.data.tokens){
		USER_LOGIN_CACHE = null;
		localStorage.setItem('admin_token', JSON.stringify(res.data.tokens));
	}
	return res;
}

var getLogin = async (force: boolean=false, token: string=null, dispatch_event: boolean=true)=>{
	if(!force && USER_LOGIN_CACHE) return { error: false, data: USER_LOGIN_CACHE, message: '' }
	var res = await post<UserLogin>('users/me', {}, {
		access_token: token
	});
	if(res.error) return res;
	USER_LOGIN_CACHE = res.data;
	localStorage.setItem('user_data', JSON.stringify(res.data));
	if(dispatch_event) window.dispatchEvent(new Event('user_data'));
	return res;
}

var onLoginToken = async (token: string, expiration: number, dispatch_event: boolean=true)=>{
	var res = await getLogin(true, token, false);
	if(!res.error){
		saveToken({ access: token, expires: expiration });
		if(dispatch_event) window.dispatchEvent(new Event('user_data'));
	}
	return res;
}

var changePassword = async (old_password: string, new_password: string)=>{
	var res = await post('users/me/password', { old_password, new_password });
	if(!res.error) logout();
	return res;
}

var getPopularEvents = (offset: number=0, count: number=50)=>{
	return cachePost<SearchEvent[]>('events/popular', 'popular_events', { offset, count });
}

var searchEvent = (query: string, offset: number=0, count: number=50)=>{
	return post<SearchEvent[]>('events/search', { query, offset, count });
}

export type EventQL = 'promoter' | 'categories' | 'types' | 'cities' | 'payment_methods' | 'delivery_methods' | 'locations' | 'event_date_status' | 'event_group_category';
var getEventsQL = async (tables: EventQL[]) : Promise<APIResponse<{[x in EventQL]: any[]}>>=>{
	var missing: EventQL[] = [], ql_data: any = {};
	for(var i of tables){
		if(CACHE[`events/ql/${i}`]){
			ql_data[i] = CACHE[`events/ql/${i}`].data;
		}else{
			missing.push(i);
		}
	}
	if(missing.length!=0){
		var res = await post('events/ql', missing);
		if(!res.error){
			for(var k in (res.data as EventQL[])){
				ql_data[k] = res.data[k];
				CACHE[`events/ql/${k}`] = { error: false, data: res.data[k], code: null, message: null };
			}
		}else{
			return res;
		}
	}

	return { error: false, data: ql_data } as APIResponse<{[x in EventQL]: any[]}>
}

var createEvent = (event_name: string, promoter_id: number, event_type: number, category_id: number)=>{
	return post<{ event_id: number }>('events/create', {
		event_name, promoter_id, event_type, category_id
	});
}

var getEvent = (event_id: number, meta: boolean=false)=>{
	return post<EventData>('events/get', { event_id, meta: meta ? 1 : 0 });
}

var getEventStatus = (event_id: number)=>{
	return post<string[]>('events/status', { event_id });
}

var setEventPoster = (event_id: number, file: File)=>{
	var data = new FormData();
	data.append('event_id', event_id.toString());
	data.append('poster', file);
	return post<boolean>('events/poster', data)
}

var saveSinopsis = (event_id: number, sinopsis: string)=>{
	return post('events/sinopsis', { event_id, sinopsis });
}

var editEvent = (event_id: number, event_name: string, promoter_id: number, event_type: number, category_id: number)=>{
	return post<boolean>('events/edit', { event_id, event_name, promoter_id, event_type, category_id });
}

var getEventDates = (event_id: number)=>{
	return post<EventDate[]>('events/date/list', { event_id });
}

var getCityVenues = (city: string)=>{
	return post<Venue[]>('venues/list/cities', { city });
}

var getAllVenues = (query: string, offset: number=0, count: number=30) => {
	return post<Venue[]>('venues/list', { offset, count, query });
}

var createVenue = (venue_name: string, location: Location) => {
	return post<{ venue_id: number }>('venues/create', { venue_name, location });
}

var editVenue = (venue_id: number, venue_name: string, location?: Location)=>{
	return post('venues/edit', { venue_id, venue_name, location });
}

var getVenue = (venue_id: number) => {
	return post<Venue>('venues/get', { venue_id });
}

var getAllVenueSections = (venue_id: number, offset=0, count=30) => {
	return post<Venue[]>(`venues/${venue_id}/sections/list`);
}

var deleteVenue = (venue_id: number)=>{
	return post<boolean>('venues/delete', { venue_id })
}

interface SectionAddData{
	section_name: string,
	description: string,
	infinite: boolean,
	numbered: boolean,
	ticket_count: number,
}

var createVenueSection = (venue_id: number, section_data: SectionAddData) => {
	return post<{ section_id: number }>(`venues/section/create`, { venue_id, ...section_data });
}

var editVenueSection = (section_id: number, section_data: SectionAddData)=>{
	return post('venues/section/edit', { section_id, ...section_data });
}

var cloneVenueSection = (section_id: number, section_name: string, description: string)=>{
	return post<{ section_id: number }>('venues/section/clone', { section_id, section_name, description });
}

interface SeatmapEditData{
	seats_width: number,
	seats_height: number,
	stage_x: number,
	stage_y: number,
	stage_width: number,
	stage_height: number,
}
interface SeatEditData{
	seat_id?: number,
	seat_row: string,
	seat_number: string,
	seat_section: string,
	seat_x: number,
	seat_y: number,
	seat_status: SeatStatus,
	status_comment: string,
	deleted: boolean,
}

var saveSeats = (section_id: number, seatmap: SeatmapEditData, seats: SeatEditData[]) => {
	return post<{
		new_seats: { seat_id: number, seat_x: number, seat_y: number }[]
	}>('venues/section/seats/edit', {
		section_id,
		seatmap,
		seats
	});
}

var getVenueSection = (venue_id: number, section_id: number, seats: boolean=false) => {
	return post<VenueSection>(`venues/section/get`, { section_id, venue_id, seats });
}

var createDate = (event_id: number, date: number, venue_id: number)=>{
	return post<{ date_id: number }>('events/date/create', { event_id, date, venue_id });
}

type EVENT_DATE_OPTS = 'sections' | 'prices' | 'date_check' | 'venue' | 'payment_methods' | 'delivery';
var getDate = (date_id: number, options?: EVENT_DATE_OPTS[], exclude?: EVENT_DATE_OPTS[])=>{
	var payload: any = { date_id };
	if(options){
		for(var i of options){
			payload[i] = 1;
		}
	}
	if(exclude){
		for(var i of exclude){
			payload[i] = 0;
		}
	}
	return post<EventDateData>('events/date/get', payload);
}

interface EditDateData{
	date?: number,
	published?: boolean,
	active_pdv?: boolean,
	active_web?: boolean,
	date_web_start?: number,
	date_web_end?: number,
	date_pdv_start?: number,
	date_pdv_end?: number,
	card_commission?: number,
	scanner_start?: number,
	scanner_end?: number,
	status_id?: number,
}

var editDate = (date_id: number, data: EditDateData)=>{
	return post('events/date/edit', { date_id, ...data });
}

var getDateSections = (date_id: number, prices: boolean=true)=>{
	return post('events/date/section/list', { date_id, prices: prices ? 1 : 0 });
}

interface SectionPrice{
	price_name: string,
	cost: number,
	commission: number,
	min_tickets: number,
	max_tickets: number,
	multiple: number,
	date_start: number,
	date_end: number,
}

var addDateSection = (date_id: number, section_id: number, date_start: number, date_end: number, prices: SectionPrice[])=>{
	return post('events/date/section/add', {
		date_id, section_id, date_start, date_end, prices
	});
}

var addDatePaymentMethod = (date_id: number, method_id: number, date_start: number, date_end: number, force_show: boolean, commission_amount: number, commission_percent: number)=>{
	return post('events/date/payment/add', { date_id, method_id, date_start, date_end, force_show, commission_amount, commission_percent });
}

var removePaymentMethod = (date_id: number, method_id: number)=>{
	return post('events/date/payment/remove', { date_id, method_id });
}

var getAdminList = (query: string, offset=0, count=30, filters?: any)=>{
	return post('users/list', { query, offset, count, filters })
}

var createAdmin = (username: string, email: string, first_name: string, last_name: string, admin_access: boolean)=>{
	return post('users/create', {
		username, email, first_name, last_name, admin_access: admin_access ? 1 : 0
	})
}

var searchAdmin = (query: string, offset: number=0, count: number=50)=>{
	return post<ShortUserAdmin[]>('users/search', { query, offset, count });
}

var addDateDelivery = (date_id: number, delivery_id: number, delivery_cost: number, date_start: number, date_end: number)=>{
	return post('events/date/delivery/add', {
		date_id, delivery_id, delivery_cost, date_start, date_end
	});
}

var removeDateDelivery = (date_id: number, delivery_id: number)=>{
	return post('events/date/delivery/remove', {
		date_id, delivery_id
	});
}

var editPrice = (price_id: number, data: SectionPrice)=>{
	var edit_data = { ...data };
	try{
		delete (edit_data as any).section_id;
	}catch(e){}
	return post('events/date/price/edit', { price_id, ...edit_data });
}

var addPrice = (date_id: number, section_id: number, data: SectionPrice)=>{
	return post('events/date/price/add', { date_id, section_id, ...data });
}

var getDateSeatmap = (date_id: number, section_id: number)=>{
	return post('events/date/map', { date_id, section_id });
}

var setDateSeatStatus = (date_id: number, status: number, seats: number[], status_comment: string)=>{
	return post('events/date/map/status', { date_id, status, seats, status_comment });
}

interface DuplicateChange{
	days: number,
	hours: number,
	minutes: number,
}
var duplicateDate = (date_id: number, origin: number, start_date: number, amount: number, start_now: boolean, change: DuplicateChange)=>{
	return post('events/date/multiply', {
		date_id, start_date, amount, start_now, change, origin
	});
}

interface EventOptions{
	subdomain?: string,
	access_code?: string,
	published?: boolean,
	meta_pixel?: string,
	google_tag?: string,
	secret?: boolean,
	commission_return?: number,
	date_start?: number,
}
var setEventOptions = (event_id: number, options: EventOptions)=>{
	return post('events/options', { event_id, ...options });
}
var setEventUserAssigned = (event_id: number, admin_id: number)=>{
	return post('events/admin', { event_id, admin_id });
}

var getUser = (admin_id: number, access: boolean=false, all_access: boolean=false)=>{
	return post('users/get', { 
		admin_id, 
		access: access ? 1 : 0,
		all_access: all_access ? 1 : 0,
	});
}

interface EditUserData{
	username?: string,
	email?: string,
	first_name?: string,
	last_name?: string,
	admin_access?: boolean,
	active?: boolean,
	change_password?: boolean,
}
var editUser = (admin_id: number, data: EditUserData)=>{
	return post('users/edit', { admin_id, data });
}

var saveAccess = (admin_id: number, access: number[])=>{
	return post('users/access', { admin_id, access });
}

var getRecentOrders = ()=>{
	return post('orders/recent');
}

var searchOrders = (query: string)=>{
	return post('orders/search', { query });
}

interface OrderOptions{
	tickets?: boolean,
	payments?: boolean,
	emails?: boolean,
	uses?: boolean,
	concilia?: boolean,
	refunds?: boolean,
	users?: boolean,
}

var getOrder = (order_hash: string, order_id?: number, options: OrderOptions={}, history: boolean=false, cache: boolean=false)=>{
	var payload_data = { 
		order_hash, order_id,
		tickets: options.tickets, 
		payments: options.payments, 
		emails: options.emails,
		uses: options.uses, 
		concilia: options.concilia,
		refunds: options.refunds,
		users: options.users,
		history: history ? 1 : 0,
	}

	if(cache){
		return cachePost('orders/get', `ord-${order_id}`, payload_data);
	}else{
		return post('orders/get', payload_data);
	}
}

var resendEmail = (order_id: number)=>{
	return post('orders/resend', { order_id });
}

var getOrderPdf = (order_id: number)=>{
	return post('orders/pdf', { order_id });
}

interface EditOrder{
	first_name?: string,
	last_name?: string,
	email?: string,
	phone?: string,
	delivery_type?: number,
	payment_method?: number,
	delivery_cost?: number,
	form_answered?: boolean,
	hidden?: boolean,
	delivered?: boolean,
	delivery_id?: number,
	delivery_location?: number,
	reference?: number,
}
var editOrder = (order_id: number, data: EditOrder)=>{
	return post('orders/edit', { order_id, ...data });
}

var convertFree = (order_id: number)=>{
	return post('orders/edit/free', { order_id });
}

var cancelOrder = (order_id: number)=>{
	return post('orders/cancel', { order_id });
}

var reviveTickets = (order_id: number, tickets: number[])=>{
	return post('orders/revive', { order_id, tickets });
}

var moveTickets = (tickets: number[], move_order: string, force: boolean=false)=>{
	return post('orders/move', { tickets, move_order, force });
}

var setOrderPaid = (order_id: number)=>{
	return post('orders/paid', { order_id });
}

var extendOrder = (order_id: number, seconds: number)=>{
	return post('orders/extend', { order_id, seconds })	;
}

var expireOrder = (order_id: number)=>{
	return post('orders/expire', { order_id });
}

var getTicketForm = (ticket_id: number)=>{
	return post('orders/ticket/form', { ticket_id });
}

var editTicketForm = (order_id: number, ticket_id: number, form: FormData)=>{
	form.append('order_id', order_id.toString());
	form.append('ticket_id', ticket_id.toString());
	return post('orders/ticket/form/edit', form);
}

var editTicket = (ticket_id: number, ticket_cost: number, commission: number)=>{
	return post('orders/ticket/edit', {
		ticket_id,
		ticket_cost, commission
	});
}

var getTicketUses = (ticket_id: number)=>{
	return post('orders/ticket/uses', { ticket_id });
}

var ticketUse = (ticket_id: number, date_used: number)=>{
	return post('orders/ticket/use', { ticket_id, date_used });
}

var deleteTicketUse = (ticket_id: number, use_id: number)=>{
	return post('orders/ticket/use/delete', { ticket_id, use_id });
}

var getOrderInvoices = (order_id: number)=>{
	return post('orders/invoices', { order_id });
}

var getOrderEmails = (order_id: number)=>{
	return post('orders/emails', { order_id });
}

var changeUserPassword = (admin_id: number, autogenerate: boolean, password: string)=>{
	return post('users/edit/password', { admin_id, autogenerate: autogenerate ? 1 : 0, password })
}

var packageConciliaParsedOrders = (orders: ParsedOrder[])=>{
	return orders.map(a=>(`${a.order_id},${a.payment_id},${a.external_id},${a.amount.toFixed(2)},${a.date_deposit},${a.deposit_type},${a.payment_method}`));
}

var getConcilia = async (orders: ParsedOrder[], type: number)=>{
	var order_payload = partition(packageConciliaParsedOrders(orders), 200);
	var orders_concilia : ConciliaDeposit[] = [];
	for(var i of order_payload){
		var res = await post<ConciliaDeposit[]>('orders/concilia/get', { orders: i, type });
		if(res.error) return res;
		orders_concilia.push(...res.data);
	}

	return { error: false, data: orders_concilia } as APIResponse<ConciliaDeposit[]>;
}

var doConcilia = (orders: ConciliaDeposit[], method: PaymentMethods[])=>{
	return post('orders/concilia/complete', { 
		payment_method: method,
		orders: packageConciliaParsedOrders(orders)
	});
}

var setTicketHidden = (ticket_id: number, hidden: boolean)=>{
	return post('orders/ticket/hidden', { ticket_id, hidden });
}

var createRefundableRequest = (date_id: number, refundable_web: boolean, refundable_pdv: boolean, refundable_complete: boolean, comments: string)=>{
	return post('events/date/refundable/request', {
		date_id, refundable_web, refundable_pdv, refundable_complete, comments
	});
}

var editDateRefundOptions = (date_id: number, refundable_web: boolean, refundable_pdv: boolean, refundable_complete: boolean, cancel_requests: boolean=false)=>{
	return post('events/date/refundable', {
		date_id, refundable_web, refundable_pdv, refundable_complete, cancel_requests
	});
}

var getDateRefundableRequests = (date_id: number, date: boolean, event: boolean, sales: boolean)=>{
	return post('events/date/refundable/get', {
		date_id, date, event, sales
	});
}

var deleteEventDate = (date_id: number)=>{
	return post('events/date/delete', { date_id });
}

var getDeliveryMethods = (query: string, offset: number=0, count: number=30) => {
	return post<DeliveryMethod[]>(`catalogs/delivery/list`, { offset, count, query });
}
var getDeliveryMethod = (delivery_id: Number) => {
	return post<DeliveryMethod>(`catalogs/delivery/get`, { delivery_id });
}

var editDelivery = (delivery_id: number, method_name: string, ticket_type: number, needs_location: boolean, delivery_cost: number) => {
	return post('catalogs/delivery/update', { delivery_id, method_name, ticket_type, needs_location, delivery_cost });
}

var createDelivery = (method_name: string, ticket_type: number, needs_location: boolean, delivery_cost: number) => {
	return post<{ delivery_id: number }>('catalogs/delivery/create', { method_name, ticket_type, needs_location, delivery_cost });
}

var getPromoters = (query: string, offset: number=0, count: number=30) => {
	return post<Promoter[]>(`promoters/list`, { offset, count, query });
}
var getPromoter = (promoter_id: Number) => {
	return post<Promoter>(`promoters/get`, { promoter_id });
}

var editPromoter = (promoter_id: number, promoter_name: string) => {
	return post('promoters/update', { promoter_id, promoter_name });
}

var createPromoter = (promoter_name: string) => {
	return post('promoters/create', { promoter_name });
}

var searchPromoter = (query: string, offset: number=0, count: number=50)=>{
	return post<Promoter[]>('promoters/search', { query, offset, count });
}

var getEventBalance = (event_id: number, payouts: boolean=true, force: boolean=false)=>{
	return post('events/balance', {
		event_id, payouts,
		cache: !force
	})
}

var getPromoterBankAccounts = (promoter_id: number)=>{
	return post(`promoters/bank/list`, { promoter_id });
}

var getPromoterContacts = (promoter_id: number)=>{
	return post(`promoters/contact/list`, { promoter_id });
}

var getPromoterContact = (contact_id: number)=>{
	return post(`promoters/contact/get`, { contact_id });
}

var editPromoterContact = (contact_id: number, contact_name: string, department: string, email: string, phone: string)=>{
	return post(`promoters/contact/update`, { contact_id, contact_name, department, email, phone });
}

var createPromoterContact = (promoter_id: number, contact_name: string, department: string, email: string, phone: string)=>{
	return post<{ contact_id: number }>(`promoters/contact/create`, { promoter_id, contact_name, department, email, phone });
}

var getPromoterEvents = (promoter_id: number, query: string, offset: number=0, count: number=50) => {
	return post(`promoters/event/list`, { promoter_id, query, offset, count });
}

var getPromoterEventsBalance = (promoter_id: number, query: string, offset: number=0, count: number=50) => {
	return post(`promoters/balance/list`, { promoter_id, query, offset, count });
}

var getPromoterBalance = (promoter_id: number) => {
	return post<{ balance: number, events: number }>(`promoters/balance`, { promoter_id });
}

interface BankAccountData{
	type: DepositType,
	email: string,
	account_owner: string
	clabe: string,
	card_number: string,
	bank_participant: number,
	rfc: string,
	postal_code: string,
	owner_type: AccountOwnerType,
	account_id?: number,
}
var createPromoterBankAccount = (promoter_id: number, data: BankAccountData)=>{
	return post<BankAccount>(`promoters/bank/create`, {
		promoter_id,
		...data
	});
}

var updatePromoterBankAccount = (data: BankAccountData)=>{
	return post<BankAccount>(`promoters/bank/update`, {
		...data
	});
}

var getPromoterBankAccount = (account_id: number)=>{
	return post(`promoters/bank/get`, { account_id });
}

interface PayoutData{
	payout_type: number,
	account_id?: number,
	date_scheduled?: number,
	comments?: string,
	completed?: boolean,
	dates: {
		date_id: number,
		amount: number,
	}[]
}
var createPayout = (event_id: number, data: PayoutData)=>{
	return post('accounting/payout/create', { event_id, ...data })
}

var getPDVList = (query: string, offset: number=0, count: number=30)=>{
	return post('pdv-catalog/list', { offset, count, query });
}

var getPDV = (pdv_id: number)=>{
	return post('pdv-catalog/get', { pdv_id });
}

var createPDV = (pdv_name: string, location: Location)=>{
	return post<{ pdv_id: number }>('pdv-catalog/create', { pdv_name, location });
}

var editPDV = (pdv_id: number, pdv_name: string, location: Location)=>{
	return post('pdv-catalog/update', { pdv_id, pdv_name, location });
}

var getPDVCortes = (pdv_id: number, offset=0, count=30) => {
	return post(`pdv-catalog/cortes/list`, { pdv_id, offset, count });
}

var getPDVAcces = (pdv_id: number, offset=0, count=30) => {
	return post(`pdv-catalog/access/list`, { pdv_id, offset, count });
}

const getPDVAccesUser = (admin_id: number, offset: number=0, count=30) => {
	return post(`pdv-catalog/user/list`, { admin_id, offset, count });
}

var reinitializePdv = (pdv_id: number)=>{
	return post<{ token: string }>('pdv-catalog/reinitialize', { pdv_id });
}

var getUserLogins = (admin_id: number, offset=0, count=30) => {
	return post(`users/logins`, { admin_id, offset, count });
}

var getPayouts = (pending: boolean, approval: boolean, completed: boolean, offset: number=0, count: number=20)=>{
	return post<{ pending?: ListPayout[], approval?: ListPayout[], completed?: ListPayout[] }>('accounting/payout/list', {
		pending,
		approval,
		completed,
		offset, 
		count
	});
}

var approvePayouts = (payouts: number[])=>{
	return post<{ approved: number[], completed: number[] }>('accounting/payout/approve', { payouts });
}

var completePayouts = (payouts: number[])=>{
	return post<{ completed: number[] }>('accounting/payout/complete', { payouts });
}

var rejectPayouts = (payouts: number[], feedback: string)=>{
	return post<{ rejected: number[] }>('accounting/payout/reject', { payouts, feedback });
}

var downloadPayoutSTP = async (payouts: number[]) : Promise<APIResponse<any>>=>{
	var res = (await post('accounting/payout/stp/txt', { payouts }, {
		response_type: 'blob'
	}) as any) as Blob;
	if(res.type==='application/json'){
		return JSON.parse(await res.text());
	}

	const link = document.createElement('a');
	const url = URL.createObjectURL(res);
	link.href = url;
	link.download = `STPLiq-${payouts.join(',')}.txt`;
	link.click();
	link.remove();

	return {
		error: false,
		code: null,
		message: null,
		data: null
	};
}

var downloadPayoutZip = async (payouts: number[], date_override?: number) : Promise<APIResponse<any>>=>{
	var res = (await post(`accounting/payout/stp/zip${date_override ? 'd='+moment.unix(date_override).format('DD-MM-YYYY') : ''}`, { payouts }, {
		response_type: 'blob'
	}) as any) as Blob;
	if(res.type==='application/json'){
		return JSON.parse(await res.text());
	}

	var date = (date_override ? moment.unix(date_override) : moment()).format('YYYY-MM-DD')

	const link = document.createElement('a');
	const url = URL.createObjectURL(res);
	link.href = url;
	link.download = `STPLiq-${date}.zip`;
	link.click();
	link.remove();

	return {
		error: false,
		code: null,
		message: null,
		data: null
	};
}

var uploadPayoutEvidence = async (payout_id:number, file: File) => {
	var data = new FormData();
	data.append('payout_id', payout_id.toString());
	data.append('evidence', file);

	return post<{ date: number, filename: string, link: string }>('accounting/payout/evidence/upload', data);
}

var removePayoutEvidence = async (payout_id:number, filename: string) => {
	return post('accounting/payout/evidence/remove', { payout_id, filename });
}

const getScannerList = (query: string, offset: number=0, count: number=30)=>{
	return post('catalogs/scanner/list', { query, offset, count});
}

const getScanner = (scanner_id: number)=>{
	return post<Scanner>('catalogs/scanner/get', { scanner_id });
}

const createScanner = (scanner: Scanner, events: number[])=>{
	return post<{ scanner_id: number }>('catalogs/scanner/create', { scanner, events });
}

const editScanner = (scanner: Scanner, events: number[])=>{
	return post<{ scanner_id: number }>('catalogs/scanner/update', { scanner, events });
}

var getPayout = (payout_id: number, evidence: boolean=false)=>{
	return post('accounting/payout/get', { payout_id, evidence });
}

const getCouponList = (query: string, offset: number=0, count: number=30)=>{
	return post('catalogs/coupon/list', { query, offset, count});
}

const getCoupon = (coupon_id: number)=>{
	return post('catalogs/coupon/get', { coupon_id });
}

const createCoupon = (coupon: Coupon, coupon_dates: number[])=>{
	return post<{ coupon_id: number }>('catalogs/coupon/create', { coupon, coupon_dates });
}

const editCoupon = (coupon: Coupon, coupon_dates: number[])=>{
	return post<{ coupon_id: number }>('catalogs/coupon/update', { coupon, coupon_dates });
}

var deleteDeposit = (deposit_id: number)=>{
	return post('orders/concilia/delete', { deposit_id });
}

var createDeposit = (payment_id: number, amount: number, date_deposit: number, deposit_type: number, external_id?: string)=>{
	return post<{ deposit_id: number }>('orders/concilia/create', { payment_id, amount, deposit_type, date_deposit, external_id });
}

var getRefunds = (pending: boolean, completed: boolean, offset: number=0, count: number=20)=>{
	return post<{ pending?: OrderRefund[],  completed?: OrderRefund[] }>('refund/list', {
		pending,
		completed,
		offset, 
		count
	});
}

const getRefund = (refund_id: number)=>{
	return post('refund/get', { refund_id });
}

var completeRefunds = (refunds: number[])=>{
	return post<{ completed: number[] }>('refund/complete', { refunds });
}

var completeRefundPayments = (refund_id: number, payments: number[])=>{
	return post('refund/payment/complete', { refund_id, payments });
}

var revertRefundPayments = (refund_id: number, payments: number[])=>{
	return post('refund/payment/revert', { refund_id, payments });
}

var cancelRefunds = (refunds: number[])=>{
	return post<{ refunds: number[] }>('refund/cancel', { refunds });
}

var pendingRefunds = (refunds: number[])=>{
	return post<{ refunds: number[] }>('refund/pending', { refunds });
}

var uploadRefundEvidence = async (refund_id:number, file: File) => {
	var data = new FormData();
	data.append('refund_id', refund_id.toString());
	data.append('evidence', file);

	return post<{ date: number, filename: string, link: string }>('refund/evidence/upload', data);
}

var removedRefundEvidence = async (refund_id:number, filename: string) => {
	return post('refund/evidence/remove', { refund_id, filename });
}

var downloadRefundZip = async (refunds: number[], date_override?: number) : Promise<APIResponse<any>>=>{
	var res = (await post(`refund/stp/zip${date_override ? 'd='+moment.unix(date_override).format('DD-MM-YYYY') : ''}`, { refunds }, {
		response_type: 'blob'
	}) as any) as Blob;
	if(res.type==='application/json'){
		return JSON.parse(await res.text());
	}

	var date = (date_override ? moment.unix(date_override) : moment()).format('YYYY-MM-DD')

	const link = document.createElement('a');
	const url = URL.createObjectURL(res);
	link.href = url;
	link.download = `STPReembolsos-${date}.zip`;
	link.click();
	link.remove();

	return {
		error: false,
		code: null,
		message: null,
		data: null
	};
}

var getEventGroups = (query: string, offset: number=0, count: number=30)=>{
	return post('parent/list', { query, offset, count });
}

const getEventGroup = (group_id: number)=>{
	return post('parent/get', { group_id });
}

const createEventGroup = (eventGroup: EventGroup, events: number[])=>{
	return post<{ group_id: number }>('parent/create', { eventGroup, events });
}

const editEventGroup = (eventGroup: EventGroup, events: number[])=>{
	return post<{ group_id: number }>('parent/update', { eventGroup, events });
}

var setEventGroupPoster = (group_id: number, file: File)=>{
	var data = new FormData();
	data.append('group_id', group_id.toString());
	data.append('poster', file);
	return post<boolean>('parent/poster', data)
}

var getTicketTemplates = (query: string, offset: number=0, count: number=10)=>{
	return post('tools/templates/list', { query, offset, count });
}

var getTicketTemplate = (ticket_id: number)=>{
	return post('tools/templates/get', { ticket_id });
}

var getTemplateTest = (zpl: string)=>{
	return post('tools/templates/test', { zpl });
}

var isSeatDeletable = (seat_id: number[])=>{
	return post<{ seat_id: number, deletable: boolean }[]>('venues/section/deletableseat', { seat_id });
}

var getUserExternals = (query: string, offset: number=0, count: number=30)=>{
	return post('catalogs/partners/list', { query, offset, count });
}

var getUserExternal = (external_id: number)=>{
	return post('catalogs/partners/get', { external_id });
}

const editUserExternal = (user: UserExternal, events: number[], promoters: number[])=>{
	return post<{ external_id: number }>('catalogs/partners/update', { user, events, promoters });
}

const createUserExternal = (user: UserExternal, events: number[], promoters: number[])=>{
	return post<{ external_id: number }>('catalogs/partners/create', { user, events, promoters });
}

var changeUserExternalPassword = (external_id: number, autogenerate: boolean, password: string)=>{
	return post('catalogs/partners/password', { external_id, autogenerate: autogenerate ? 1 : 0, password })
}

var getUserExternalAccess = (external_id: number, event_id: number, access: boolean, all_access: boolean)=>{
	return post('catalogs/partners/access', { external_id, event_id, access, all_access });
}

var saveUserExternalEventAccess = (external_id: number, event_id: number, access: number[]) => {
	return post('catalogs/partners/access/save', { external_id, event_id, access });
}

var updateTicketSeat = (ticket_id: number, new_seat_id: number)=>{
	return post('orders/ticket/seat', { ticket_id, new_seat_id })
}

var deleteSection = (section_id: number)=>{
	return post<boolean>('venues/section/delete', { section_id })
}

var getDateSectionHolders = (date_id: number, section_id: number)=>{
	return post('events/date/section/hold/list', { date_id, section_id });
}

var createSectionHold = (date_id: number, section_id: number, amount: number, comments: string) => {
	return post<{ hold_id: number }>('events/date/section/hold/create', {
		date_id, section_id,
		amount, comments
	});
}

var updateSectionHold = (hold: SectionHold) => {
	return post('events/date/section/hold/update', {
		hold_id: hold.hold_id,
		amount: hold.amount,
		comments: hold.comments,
	});
}

var deleteSectionHold = (hold_id: number) => {
	return post('events/date/section/hold/delete', { hold_id });
}

var getUsers = (users: number[])=>{
	return post<UserAdmin[]>('users/list/names', { users });
}

interface RefundOptions{
	commission?: boolean,
	delivery?: boolean,
	payment?: boolean,
	email?: string,
	completed?: boolean,
	send_email?: boolean,
}

var createRefund = (order_id: number, tickets: number[], options: RefundOptions)=>{
	return post<{
		refund: OrderRefund,
		payments?: {
			payment_id: number,
			amount: number,
		}[],
		tickets?: {
			ticket_id: number,
			amount_refunded: number,
			commission_refunded: number,
		}[],
	}>('orders/refund', { order_id, tickets, ...options });
}

/* var getInvoices = (query: string, offset: number=0, count: number=30, params: any)=>{
	return post('reports/invoices', { query, offset, count, params });
} */
var viewRefundRequest = (refund_id: number)=>{
	return post<{ request_url: string }>('refund/request/view', { refund_id });
}

var resendRefundRequest = (refund_id: number, email: string)=>{
	return post('refund/request/resend', { refund_id, email });
}

var getPapeletaDays = (days: number=60)=>{
	return post<{ date: string, deposits: number }[]>('accounting/papeleta/days', { days });
}

var getPapeletaDay = (day: string)=>{
	return post<PapeletaData>('accounting/papeleta/day', { day });
}

var getEventOrdersConcilia = (event_id: number, method_id: number, query: string, offset: number=0, count: number=30)=>{
	return post('events/conciliacion', { event_id, method_id, query, offset, count });
}

var getInvoices = (date_start: number, date_end: number, offset: number=0, count: number=30)=>{
	return post<ReportResponse<{
		invoice_id: number,
		order_hash: number,
		legal_name: string,
		rfc: string,
		amount: number,
	}>>('reports/invoices', { date_start, date_end, offset, count });
}

var getPaymentInfo = (payment_id: number) => {
	return post('orders/payment', { payment_id });
}

var updatePayment = (payment_id: number, payment_cost: number, delivery_cost: number, total: number) => {
	return post('orders/payment/update', { payment_id, payment_cost, delivery_cost, total });
}

var getLocationInfo = (location_id: number) => {
	return post('orders/location', { location_id });
}

var updateLocation = (location: Location) => {
	return post('orders/location/update', { location });
}

var createLocation = (location: Location) => {
	return post('orders/location/create', { location });
}

var updateOrderLocation = (order_id: number, location_id: number) => {
	return post('orders/location/order', { order_id, location_id });
}

var sendRefundInfoRequest = (refund_id: number, email: string)=>{
	return post('refund/payment/info', { refund_id, email });
}

var getDatePaymentsList = (start_date: number, days: number) => {
	return post('reports/payments/list', { start_date, days });
}

var getDatePayments = (day: string) => {
	return post('reports/payments/get', { day });
}

var resendOrderEmail = (order_id: number, email_id: number) => {
	return post('orders/emails/resend', { order_id, email_id });
}

var setDateQueue = (date_id: number, queue: boolean)=>{
	return post<{ queue_id: number }>('events/date/queue', { date_id, queue });
}

var getScannerBarcode = (scanner_id: number, token: string)=>{
	return post<{ barcode: string, qrcode: string }>('tools/barcode/scanner', { scanner_id, token });
}

var reinitializeScanner = (scanner_id: number)=>{
	return post('catalogs/scanner/reinitialize', { scanner_id });
}

var resetScannerToken = (scanner_id: number)=>{
	return post<{ token: string }>('catalogs/scanner/token', { scanner_id });
}

var getEventDateForms = (event_id: number)=>{
	return post('events/date/forms/list', { event_id });
}

var getEventDateQuestions = (date_id: number)=>{
	return post('events/date/forms/questions', { date_id });
}

var createDateQuestion = (date_id: number, question: { question_type: number, question: string, active: boolean,comment: string, default_value: string, required: boolean,values: any[] })=>{
	return post('events/date/forms/question/save', { date_id, question });
}

var updateDateQuestion = (question: { question_id: number, question_type: number, question: string, active: boolean,comment: string, default_value: string, required: boolean,values: any[] })=>{
	return post('events/date/forms/question/update', { question });
}

var deleteQuestion = (question_id: number) => {
	return post('events/date/forms/question/delete', { question_id });
}

var getEventDateQuestion = (question_id: number)=>{
	return post('events/date/forms/question', { question_id });
}

var updateQuestionOrder = (questions : { question_id: number, sort_order: number }[])=>{
	return post('events/date/forms/question/order', { questions });
}

var createQuestionValue = (option: { question_id: number, value: string, active: boolean })=>{
	return post('events/date/forms/question/value/save', { option });
}

var updateQuestionValue = (option: { value_id: number, value: string, active: boolean })=>{
	return post('events/date/forms/question/value/update', { option });
}

var deleteQuestionValue = (value_id: number) => {
	return post('events/date/forms/question/value/delete', { value_id });
}

var updateQuestionValueOrder = (values : { value_id: number, sort_order: number }[])=>{
	return post('events/date/forms/question/value/order', { values });
}

export default {
	addDateDelivery,
	addDatePaymentMethod,
	addDateSection,
	addPrice,
	approvePayouts,
	cancelOrder,
	cancelRefunds,
	changePassword,
	changeUserExternalPassword,
	changeUserPassword,
	cloneVenueSection,
	completePayouts,
	completeRefundPayments,
	completeRefunds,
	convertFree,
	createAdmin,
	createCoupon,
	createDate,
	createDelivery,
	createDeposit,
	createEvent,
	createEventGroup,
	createLocation,
	createPayout,
	createPDV,
	createPromoter,
	createPromoterBankAccount,
	createPromoterContact,
	createRefund,
	createRefundableRequest,
	createScanner,
	createSectionHold,
	createUserExternal,
	createVenue,
	createVenueSection,
	deleteDeposit,
	deleteEventDate,
	deleteSection,
	deleteSectionHold,
	deleteTicketUse,
	deleteVenue,
	doConcilia,
	downloadPayoutSTP,
	downloadPayoutZip,
	downloadRefundZip,
	duplicateDate,
	editCoupon,
	editDate,
	editDateRefundOptions,
	editDelivery,
	editEvent,
	editEventGroup,
	editOrder,
	editPDV,
	editPrice,
	editPromoter,
	editPromoterContact,
	editScanner,
	editTicket,
	editTicketForm,
	editUser,
	editUserExternal,
	editVenue,
	editVenueSection,
	expireOrder,
	extendOrder,
	getAdminList,
	getAllVenues,
	getAllVenueSections,
	getAuthUrl,
	getCityVenues,
	getConcilia,
	getCoupon,
	getCouponList,
	getDate,
	getDatePayments,
	getDatePaymentsList,
	getDateRefundableRequests,
	getDateSeatmap,
	getDateSectionHolders,
	getDateSections,
	getDeliveryMethod,
	getDeliveryMethods,
	getEvent,
	getEventBalance,
	getEventDates,
	getEventGroup,
	getEventGroups,
	getEventOrdersConcilia,
	getEventsQL,
	getEventStatus,
	getInvoices,
	getLocationInfo,
	getLogin,
	getOrder,
	getOrderEmails,
	getOrderInvoices,
	getOrderPdf,
	getPapeletaDay,
	getPapeletaDays,
	getPaymentInfo,
	getPayout,
	getPayouts,
	getPDV,
	getPDVAcces,
	getPDVAccesUser,
	getPDVCortes,
	getPDVList,
	getPopularEvents,
	getPromoter,
	getPromoterBalance,
	getPromoterBankAccount,
	getPromoterBankAccounts,
	getPromoterContact,
	getPromoterContacts,
	getPromoterEvents,
	getPromoterEventsBalance,
	getPromoters,
	getRecentOrders,
	getRefund,
	getRefunds,
	getScanner,
	getScannerBarcode,
	getScannerList,
	getTemplateTest,
	getTicketForm,
	getTicketTemplate,
	getTicketTemplates,
	getTicketUses,
	getToken,
	getUser,
	getUserExternal,
	getUserExternalAccess,
	getUserExternals,
	getUserLogins,
	getUsers,
	getVenue,
	getVenueSection,
	isSeatDeletable,
	login,
	logout,
	moveTickets,
	onLoginToken,
	pendingRefunds,
	reinitializePdv,
	reinitializeScanner,
	rejectPayouts,
	removeDateDelivery,
	removedRefundEvidence,
	removePaymentMethod,
	removePayoutEvidence,
	resendEmail,
	resendOrderEmail,
	resendRefundRequest,
	resetScannerToken,
	revertRefundPayments,
	reviveTickets,
	saveAccess,
	saveSeats,
	saveSinopsis,
	saveUserExternalEventAccess,
	searchAdmin,
	searchEvent,
	searchOrders,
	searchPromoter,
	sendRefundInfoRequest,
	setDateQueue,
	setDateSeatStatus,
	setEventGroupPoster,
	setEventOptions,
	setEventPoster,
	setEventUserAssigned,
	setOrderPaid,
	setTicketHidden,
	ticketUse,
	updateLocation,
	updateOrderLocation,
	updatePayment,
	updatePromoterBankAccount,
	updateSectionHold,
	updateTicketSeat,
	uploadPayoutEvidence,
	uploadRefundEvidence,
	viewRefundRequest,
	getEventDateForms,
	getEventDateQuestions,
	createDateQuestion,
	updateDateQuestion,
	deleteQuestion,
	getEventDateQuestion,
	updateQuestionOrder,
	createQuestionValue,
	updateQuestionValue,
	deleteQuestionValue,
	updateQuestionValueOrder,
}