import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Field, Groupper, Header, Input, Message } from "react-frontier";
import { DeliveryMethod, SetLoading, TicketType } from "@arema/components/Classes";
import { bindFormChange } from "@arema/components/Util";
import { Select, Checkbox } from 'semantic-ui-react';
import { DeletedBanner, ErrorHeader, NotFound } from "../../components";
import API from "../../API";
import Toast from "react-hot-toast";
import Validator from "@arema/components/Validator";

const TICKET_TYPES = [
	{ id: TicketType.PHYSICAL, name: 'Boleto fisico', },
	{ id: TicketType.DIGITAL, name: 'Boleto digital', },
]

var DeliveryMethodCreate = () => {
	var { delivery_id } = useParams();
	var [delivery, setDelivery] = useState<DeliveryMethod>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var IS_CREATE = delivery_id === 'create';
	var navigate = useNavigate()

	var loadData = async (delivery_id: number) => {
		API.getDeliveryMethod(delivery_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setDelivery({
				...res.data,
				needs_location: !!res.data.needs_location
			});
		}).catch(err => {
			setLoadError('Hubo un error cargando forma de envio (LCL-3)');
		});
	}

	useEffect(() => {
		if(!IS_CREATE && !Number.isNaN(parseInt(delivery_id))){
			loadData(parseInt(delivery_id));
		}	else{
			setDelivery({
				delivery_id: null,
				method_name: '',
				ticket_type: null,
				needs_location: false,
				delivery_cost: null,
			})
		}
	}, []);

	if(!IS_CREATE && Number.isNaN(parseInt(delivery_id))){
		return <NotFound />
	}
	if(loadError){
		return <ErrorHeader error={loadError} />
	}
	if(!delivery){
		return <Header loading text="Cargando forma de envío" />
	}

	var submit = async (setLoading: SetLoading) => {
		var { valid, prompts } = Validator(delivery, {
			method_name: [{
				rule: 'length', params: [4, 32], label: 'Nombre'
			}],
			delivery_cost: [{
				rule: 'number', label: 'Costo de Envio'
			}],
			ticket_type: [{
				rule: 'number', label: 'Tipo de ticket',
			}]
		});
		setErrorPrompts(prompts);
		if (!valid) return;
		if (!IS_CREATE) {
			delivery.delivery_id = parseInt(delivery_id);
		}
		setLoading(true);
		if (IS_CREATE) {
			API.createDelivery(delivery.method_name, delivery.ticket_type, delivery.needs_location, delivery.delivery_cost).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha creado la forma de envío');
				navigate(`/c/delivery/${res.data.delivery_id}`);
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado creando la forma de envio (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editDelivery(delivery.delivery_id, delivery.method_name, delivery.ticket_type, delivery.needs_location, delivery.delivery_cost).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha editando la forma de envio.');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado editando la forma de envio (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	var onFormChange = bindFormChange(delivery, setDelivery);

	return (
		<div>
			{	delivery.deleted ? <DeletedBanner date_deleted={delivery.date_deleted} /> : null	}
			<Groupper title={IS_CREATE ? 'Crear Forma de Envio' : `Editar Forma de Envio`} width={500} actions={(
				<Button text="Guardar" color="black" onClick={submit} />
			)}>
				<Input
					label="Nombre"
					placeholder="Nombre"
					value={delivery.method_name}
					onChange={onFormChange('method_name')}
				/>
				<Field amount={2}>
					<Field label={'Tipo de boleto'}>
						<Select
							value={delivery.ticket_type}
							onChange={onFormChange('ticket_type', true)}
							placeholder='Tipo de boleto'
							options={TICKET_TYPES.map(t => ({
								key: t.id,
								value: t.id,
								text: t.name
							}))}
						/>
					</Field>
					<Input
						inputType="number"
						label="Costo de envio"
						value={delivery.delivery_cost}
						onChange={onFormChange('delivery_cost')}
					/>
				</Field>
				<Field label={'Necesita ubicación'} comment="Se le pedirá a el cliente que ingrese una dirección.">
					<Checkbox
						toggle
						label={'Necesita ubicación'}
						checked={!!delivery.needs_location}
						onChange={onFormChange('needs_location', true)}
					/>
				</Field>
				<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
			</Groupper>
		</div>
	)
}

export default DeliveryMethodCreate;