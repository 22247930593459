import React, { useState, useEffect } from "react";
import { Link, LinkProps, useParams } from "react-router-dom";
import { EventSectionProps, SetLoading } from "@arema/components/Classes";
import { Button, Header, Icon } from "react-frontier";
import { arrayMoveImmutable } from "array-move";
import SortableList, { SortableItem } from "react-easy-sort";
import API from "../../API";
import Toast from "react-hot-toast";

enum QuestionType {
	OPEN = 1,
	TEXTBOX = 2,
	SELECTION = 10,
	MULTIPLE = 11,
	YES_NO = 12,
	RATING = 13,
	FILE_DOWNLOAD = 30,
	FILE_IMAGE = 31,
	FILE_OTHER = 32,
	DATE = 50,
	STATIC_TEXT = 90,
}

interface DateQuestion {
	question_id?: number,
	question_type: QuestionType,
	question: string,
	active: boolean,
	comment: string,
	default_value: string,
	required: boolean,
	sort_order?: number,
}

var LinkCell = (p: LinkProps) => <td><Link {...p}>{p.children}</Link></td>

var EventFormsContainer = (props: EventSectionProps) => {
	var params = useParams();
	var event_id = parseInt(params.id);
	const p = params['*'].split('/')
	var date_id = parseInt(p[1]);

	var [datesQuestions, setDatesQuestions] = useState<DateQuestion[]>(null);
	var [questionsOrdered, setQuestionsOrdered] = useState<DateQuestion[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [sortMode, setSortMode] = useState<boolean>(false);

	var loadDatesForms = () => {
		API.getEventDateQuestions(date_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setDatesQuestions(res.data);
			setQuestionsOrdered(res.data);
		}).catch(err => {
			setLoadError('Hubo un error cargando las preguntas. (LCL-1)');
		});
	}

	useEffect(() => {
		if (!datesQuestions) {
			loadDatesForms();
		}
	}, []);

	const onSortEnd = (oldIndex: number, newIndex: number) => {
		setQuestionsOrdered((array) => arrayMoveImmutable(array, oldIndex, newIndex));
	};

	var onSaveOrder = (setLoading: SetLoading) => {
		setLoading(true);
		var qOrder: { question_id: number, sort_order: number }[] = questionsOrdered.map((q, index) => ({ question_id: q.question_id, sort_order: questionsOrdered.length - index }))
		API.updateQuestionOrder(qOrder).then((res) => {
			if (res.error) return setLoadError(res.message);
			Toast.success("Se guardaron las preguntas");
			setDatesQuestions([...questionsOrdered]);
			setSortMode(false);
		}).catch(() => {
			setLoadError('Hubo un error guardando las preguntas. (LCL-1)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var deleteQuestion = (question_id: number) => {
		return (setLoading: SetLoading) => {
			setLoading(true);
			API.deleteQuestion(question_id).then((res) => {
				if (res.error) return setLoadError(res.message);
				var newQ = datesQuestions.filter(q => q.question_id !== question_id )
				setDatesQuestions([...newQ]);
				setQuestionsOrdered([...newQ]);
				Toast.success("Se elimino la pregunta");
			}).catch(() => {
				setLoadError('Hubo un error eliminando la pregunta. (LCL-1)');
			}).finally(() => {
				setLoading(false);
			})
		}
	}

	if (loadError) return <Header text="Error" subtext={loadError} />
	if (!datesQuestions) return <Header loading text="Cargando preguntas" />

	return <div>
		<table className="fr compact selectable table" style={{ margin: 'auto', maxWidth: 800 }}>
			<thead>
				<tr>
					<th colSpan={2} className="title">Preguntas</th>
					<th colSpan={3} style={{ textAlign: 'right' }} >
						{!sortMode ? <>
							<Button size='tiny' text="Ordenar" onClick={() => { setSortMode(true) }} style={{ marginRight: 5 }} />
							<Button size='tiny' text="Crear Pregunta" color="black" as={Link} icon iconName='add' to={`/events/${event_id}/forms/${date_id}/q/create`} />
						</>
							:
							<>
								<Button size='tiny' text="Cancelar" style={{ marginRight: 5 }} onClick={() => { setSortMode(false) }} />
								<Button size='tiny' text="Guardar" color="black" onClick={onSaveOrder} />
							</>
						}
					</th>
				</tr>
				<tr>
					<th className="collapsing">ID</th>
					<th>Pregunta</th>
					<th>Tipo</th>
					<th style={{ textAlign: 'center' }} className="collapsing">Activa</th>
					<th style={{ textAlign: 'center' }} className="collapsing"><Icon name="remove" /></th>
				</tr>

			</thead>

			{!sortMode ? (<tbody>
				{datesQuestions.length === 0 ? (
					<tr className="normal">
						<td colSpan={7} className="empty">No hay preguntas en este calendario.</td>
					</tr>
				) : datesQuestions.map(a => (
					<tr key={`qt-${a.question_id}`}>
						<LinkCell to={`/events/${event_id}/forms/${date_id}/q/${a.question_id}`}>{a.question_id}</LinkCell>
						<LinkCell to={`/events/${event_id}/forms/${date_id}/q/${a.question_id}`}>{a.question}</LinkCell>
						<LinkCell to={`/events/${event_id}/forms/${date_id}/q/${a.question_id}`}>{QuestionType[a.question_type]}</LinkCell>
						<LinkCell to={`/events/${event_id}/forms/${date_id}/q/${a.question_id}`}>{<Icon name={a.active ? 'check' : 'remove'} color={a.active ? 'green' : 'black'} />}</LinkCell>
						<td style={{ textAlign: 'center' }} > <Button icon iconName="remove" className="tiny" onClick={deleteQuestion(a.question_id)} /> </td>
					</tr>
				))}
			</tbody>)
				: <SortableList
					onSortEnd={onSortEnd}
					draggedItemClassName="dragged"
					as={'tbody'}
				>
					{questionsOrdered.map((q) => (
						<SortableItem key={`qt-o-${q.question_id}`}>
							<tr style={{ backgroundColor: '#fff' }} >
								<td style={{ padding: '.75rem 1rem', zIndex: 1 }} >{q.question_id}</td>
								<td style={{ padding: '.75rem 1rem', zIndex: 1 }} >{q.question}</td>
								<td style={{ padding: '.75rem 1rem', zIndex: 1 }} >{QuestionType[q.question_type]}</td>
								<td style={{ padding: '.75rem 1rem', zIndex: 1 }} >{<Icon name={q.active ? 'check' : 'remove'} color={q.active ? 'green' : 'black'} />}</td>
							</tr>
						</SortableItem>
					))}
				</SortableList>
			}
		</table>
	</div>
}

export default EventFormsContainer;