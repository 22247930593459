import React, { useState, useEffect } from 'react'
import { Button, Field, Groupper, Header, Input, Message } from "react-frontier";
import { CatalogEvent, Coupon, EventDate } from '@arema/components/Classes';
import { Checkbox, Icon, Select, TextArea } from 'semantic-ui-react';
import { useParams } from "react-router-dom";
import { addCommas, bindClose, bindFormChange } from '@arema/components/Util';
import { DeletedBanner, ErrorHeader, NotFound } from '../../components';
import { useTitle } from '@arema/components/Hooks';
import Validator from "@arema/components/Validator";
import API from "./../../API";
import SearchEventModal from "../../components/SearchEventModal";
import Toast from "react-hot-toast";

import './../../style/scanner.scss';
import moment from 'moment';

const CUPON_TYPES = [
	{
		id: 1,
		name: 'Cantidad'
	},
	{
		id: 2,
		name: 'Porcentaje'
	},
	{
		id: 10,
		name: '? x ?'
	},
	{
		id: 11,
		name: '? x $'
	}
];

interface CouponData{
	coupon_id: number
	coupon_code: string, 
	description: string,
	type_id: number,
	param1: number,
	param2: number,
	active: boolean,
	date_start: number,
	date_end: number,
	deleted?: boolean,
	date_deleted?: number,
}

var CouponEdit = () => {
	var { coupon_id } = useParams();
	var { setTitle } = useTitle();
	var IS_CREATE = coupon_id === 'create';
	var [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
	var [selectedDates, setSelectedDates] = useState<(EventDate & { event: string })[]>([]);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [formData, setFormData] = useState<CouponData>(null)
	var [loadError, setLoadError] = useState<string>(null);

	var onSaveEvents = (event: CatalogEvent, date: EventDate): boolean => {
		if (!selectedDates.some(ev => ev.date_id === date.date_id)) {
			var newEvents = [...selectedDates];
			newEvents.push({ ...date, event: event.event_name });
			setSelectedDates(newEvents);
			return true;
		}
		Toast.error('La fecha ya fue seleccionado previamente');
		return false;
	}

	useEffect(() => {
		if (!IS_CREATE && !Number.isNaN(parseInt(coupon_id))) {
			if(!formData){
				loadData(parseInt(coupon_id));
			}
		} else {
			setTitle('Crear cupón');
			setFormData({
				coupon_id: null,
				coupon_code: '',
				description: '',
				type_id: 1,
				param1: null,
				param2: null,
				active: true,
				date_start: null,
				date_end: null,
			})
		}
	}, []);

	var loadData = async (coupon_id: number) => {
		var res = await API.getCoupon(coupon_id);
		if (!res.error) {
			setFormData({
				...res.data,
				active: !!res.data.active,
			});
			setTitle(`Cupón ${res.data.coupon_code}`);
			if (res.data.dates) {
				setSelectedDates(res.data.dates);
			}
		} else {
			setLoadError('Hubo un error cargando el cupón (LCL-3)');
		}
	}

	var deleteEvents = (date_id: number) => {
		return () => {
			var newEvents = [...selectedDates].filter(sev => sev.date_id !== date_id);
			setSelectedDates(newEvents);
		}
	}

	var submit = async (setLoading: (b: boolean) => void) => {
		var { valid, prompts } = Validator(formData, {
			coupon_code: [{ rule: 'length', params: [3, 32] }, { rule: /^[A-Za-z0-9][A-Za-z0-9 ]*$/ }],
			description: [{ rule: 'length', params: [3, 128] }],
		});
		setErrorPrompts(prompts);
		if (!valid) return;
		if (!IS_CREATE) {
			formData.coupon_id = parseInt(coupon_id);
		}
		setLoading(true);
		var saveCoupon: Coupon = {
			coupon_id: formData.coupon_id,
			coupon_code: formData.coupon_code,
			description: formData.description,
			type_id: formData.type_id,
			param1: formData.param1,
			param2: formData.param2,
			active: formData.active,
			date_start: formData.date_start,
			date_end: formData.date_end,
		}
		if (IS_CREATE) {
			API.createCoupon(saveCoupon, selectedDates.map(d => d.date_id)).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se creó el cupón');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperadoal crear el cupón (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editCoupon(saveCoupon, selectedDates.map(d => d.date_id)).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha editado el cupón.');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado editando el cupón (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}
	if(!IS_CREATE && Number.isNaN(parseInt(coupon_id))){
		return <NotFound />
	}
	if (loadError) {
		return <ErrorHeader error={loadError} />
	}
	if (!formData) {
		return <Header loading text='Cargando cupón' />
	}

	var onFormChange = bindFormChange(formData, setFormData);

	return (
		<div>
			{ formData.deleted && <DeletedBanner date_deleted={formData.date_deleted} /> }
			<Groupper
				title={IS_CREATE ? 'Nuevo cupón' : 'Editar cupón'}
				width={600}
				actions={(
					<Button text="Guardar" color="black" onClick={submit} />
				)}
			>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
					<Input as='fr fluid input' label="Codigo" placeholder="Codigo" value={formData.coupon_code} onChange={onFormChange('coupon_code')} />
					<Field label='Activo' style={{ textAlign: 'center', width: 150 }}>
						<Checkbox toggle checked={!!formData.active} onChange={onFormChange('active', true)} />
					</Field>
				</div>
				<Field label={'Descripcion'}>
					<TextArea value={formData.description} onChange={onFormChange('description', true)} placeholder='Descripcion' />
				</Field>
				<Field amount={[1, 2].includes(formData.type_id) ? 2 : 3}>
					<Field label={'Tipo de cupón'}>
						<Select
							fluid
							value={formData.type_id}
							onChange={onFormChange('type_id', true)}
							placeholder='Tipo de cupón'
							options={CUPON_TYPES.map(t => ({
								key: t.id,
								value: t.id,
								text: t.name
							}))}
						/>
					</Field>
					<Input inputType="number" label="Cantidad" value={formData.param1} onChange={onFormChange('param1')} />
					{[10, 11].includes(formData.type_id) && (
						<Input label="Cantidad" inputType="number" value={formData.param2} onChange={onFormChange('param2')} style={{ width: 'auto' }} />
					)}
				</Field>

				{!!formData.type_id && (
					<Message type='info' style={{ margin: 'auto', marginBottom: 10, marginTop: 5, maxWidth: 400 }} centered>
						Este cupón hara el siguiente descuento: <br />
						<b>
							{formData.type_id==1 ? (
								`Se descontará $${addCommas(formData.param1)} MXN del precio del boleto.`
							) : formData.type_id==2 ? (
								`Se descontará ${addCommas(formData.param1, false)}% del precio del boleto.`
							) : formData.type_id==10 ? (
								<div>
									Se hará una promoción del {formData.param1}x{formData.param2}
									<br />
									<span style={{ fontWeight: 'normal', color: 'gray' }}>({formData.param1} boleto{formData.param1==1 ? '' : 's'} al precio de {formData.param2} boleto{formData.param2==1 ? '' : 's'})</span>
								</div>
							) : formData.type_id==11 ? (
								`Se hará una promoción de ${formData.param1} boletos a $${formData.param2} MXN`
							) : null}
						</b>
					</Message>
				)}

				<Field amount={2}>
					<Input label="Fecha Inicio" value={formData.date_start} onChange={onFormChange('date_start')} calendar={{
						date: formData.date_start,
						mode: 'date',
					}}/>
					<Input label="Fecha Fin" value={formData.date_end} onChange={onFormChange('date_end')} calendar={{
						date: formData.date_end,
						mode: 'date'
					}} />
				</Field>

				<table className="fr fitted first table" style={{ maxWidth: 600, marginTop: 15 }}>
					<thead>
						<tr>
							<th className='title' colSpan={5}>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									Fechas
									<Button text='Agregar' color='black' size='tiny' iconName='plus-circle' onClick={bindClose(setOpenSearchModal, true)} />
								</div>
							</th>
						</tr>
						<tr>
							<th className="collapsing">ID</th>
							<th>Evento</th>
							<th>Fecha</th>
							<th>Ciudad</th>
							<th className='centered collapsing'><i className="remove icon"></i></th>
						</tr>
					</thead>
					<tbody>
						{selectedDates.length === 0 ? (
							<tr className="normal">
								<td colSpan={7} className="empty">No hay fechas para este cupón</td>
							</tr>
						) : (
							selectedDates.map(e => (
								<tr key={`sevt-${e.date_id}`}>
									<td className="collapsing">{e.date_id}</td>
									<td>{e.event}</td>
									<td className="collapsing">{moment.unix(e.date).format('DD/MM/YY')}</td>
									<td className="collapsing">{e.city}</td>
									<td className='collapsing'>
										<Button iconName='remove' color='black' size='tiny' style={{ minWidth: 40 }} onClick={deleteEvents(e.date_id)} />
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>

				<Message type='error' list={errorPrompts} style={{ marginTop: 15 }} />
			</Groupper>

			<SearchEventModal
				date={true}
				open={openSearchModal}
				onClose={bindClose(setOpenSearchModal)}
				title='Buscar Fecha'
				onDateSelected={onSaveEvents}
			/>
		</div>
	)
}

export default CouponEdit;
