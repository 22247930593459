import React, { useState, useEffect } from "react";
import { Header } from 'react-frontier';
import { Link, LinkProps } from "react-router-dom";
import { EventSectionProps } from "@arema/components/Classes";
import API from "../../API";
import moment from "moment";

var LinkCell = (p: LinkProps)=><td><Link {...p}>{p.children}</Link></td>

interface QuestionsEventDate {
	date_id: number,
	event_id: number,
	date: number,
	venue_name: string,
	city: string,
	questions_count: number
}

var EventFormsContainer = (props: EventSectionProps)=>{
	var [datesQuestions, setDatesQuestions] = useState<QuestionsEventDate[]>(null);
	var [loadError, setLoadError] = useState<string>(null);

	var loadDatesForms = ()=>{
		API.getEventDateForms(props.event.event_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			setDatesQuestions(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando los calendarios. (LCL-1)');
		});
	}

	useEffect(()=>{
		if(!datesQuestions){
			loadDatesForms();
		}
	}, []);

	if(loadError) return <Header text="Error" subtext={loadError} />
	if(!datesQuestions) return <Header loading text="Cargando calendarios" />

	return <div>
		<table className="fr compact selectable table" style={{ margin: 'auto', maxWidth: 800 }}>
			<thead>
				<tr>
					<th colSpan={5} className="title">Calendarios</th>
				</tr>
				<tr>
					<th className="collapsing">ID</th>
					<th>Fecha</th>
					<th>Recinto</th>
					<th>Ciudad</th>
					<th>Preguntas</th>
				</tr>
			</thead>
			<tbody>
				{datesQuestions.length==0 ? (
					<tr className="normal">
						<td colSpan={7} className="empty">No hay calendarios en este evento.</td>
					</tr>
				) : (
					datesQuestions.map(a=>(
						<tr key={`dte-${a.date_id}`}>
							<LinkCell to={`/events/${a.event_id}/forms/${a.date_id}`}>{a.date_id}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/forms/${a.date_id}`}>{moment.unix(a.date).format('DD/MMM/YY HH:mm')}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/forms/${a.date_id}`}>{a.venue_name}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/forms/${a.date_id}`}>{a.city}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/forms/${a.date_id}`}>{a.questions_count}</LinkCell>
						</tr>
					))
				)}
			</tbody>
		</table>
	</div>
}

export default EventFormsContainer;