import React, { useState, useEffect } from "react";
import { Checkbox, Button, Field, Groupper, Input, Message, Header, Table, Icon } from 'react-frontier';
import { Dropdown, DropdownItemProps, Loader, Modal } from "semantic-ui-react";
import { addCommas, bindClose, bindFormChange, formatCommission, isActive, unix } from "@arema/components/Util";
import { DeliveryMethod, EventDateData, EventSectionProps, PaymentMethod, SetLoading, TicketType } from "@arema/components/Classes";
import { Seatmap, SectionHoldModal, UserLog } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../AdminHooks";
import API from "../../API";
import moment from "moment";
import Validator from "@arema/components/Validator";
import Toast from "react-hot-toast";
import classNames from "classnames";
import UserAccess from "../../UserAccess";

interface EventDateParams extends EventSectionProps {
	params: {
		id: number
	}
}

interface SelectedSection {
	section_id: number,
	section_name: string,
	date_start: number,
	date_end: number,
	numbered: boolean,
	published: boolean,
	infinite?: boolean,
}

interface SelectedPrice {
	price_id?: number,
	section_id: number,
	price_name: string,
	cost: number,
	min_tickets: number,
	max_tickets: number,
	multiple: number,
	commission: number,
	date_start: number,
	date_end: number,
	published_web?: boolean,
	published_pdv?: boolean,
}

interface SelectedPayment {
	edit: boolean,
	method_id: number,
	method_name: string,
	date_start: number,
	date_end: number,
	force: boolean,
	change_comission?: boolean,
	commission_percent?: number,
	commission_amount?: number,
}

interface SelectedDelivery {
	edit: boolean,
	delivery_id: number,
	method_name: string,
	delivery_cost: number,
	date_start: number,
	date_end: number,
}

interface SelectedSeatmap {
	section_id: number,
	section_name: string,
}

interface RefundRequest {
	sent: boolean,
	refundable_web: boolean,
	refundable_pdv: boolean,
	refundable_complete: boolean,
	comments: string
}

interface EventDateStatus {
	status_id: number,
	status_name: string,
}

enum ModalType {
	DELETE_EVENTDATE = 1,
	EDIT_SECTION = 2,
	SECTION_HOLD = 3,
}

var EventDateComponent = (props: EventDateParams) => {
	var navigate = useNavigate();
	var { hasAccess } = useUser();
	var [date, setDate] = useState<EventDateData>();
	var [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>(null);
	var [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]>(null);
	var [eventDateStatus, setEventDateStatus] = useState<EventDateStatus[]>(null);
	var [loadingSections, setLoadingSections] = useState<boolean>(false);
	var [datePrompts, setDatePrompts] = useState<string[]>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [shownSectionPrices, setShownSectionPrices] = useState<number[]>([]);

	var [addPaymentModal, setAddPaymentModal] = useState<boolean>(false);
	var [addDeliveryModal, setAddDeliveryModal] = useState<boolean>(false);
	var [selectedSection, setSelectedSection] = useState<SelectedSection>(null)
	var [selectedPayment, setSelectedPayment] = useState<SelectedPayment>(null);
	var [selectedDelivery, setSelectedDelivery] = useState<SelectedDelivery>(null);
	var [selectedSeatmap, setSelectedSeatmap] = useState<SelectedSeatmap>(null);
	var [editingPrice, setEditingPrice] = useState<SelectedPrice>(null);
	var [confirmPaymentModal, setConfirmPaymentModal] = useState<boolean>(false);
	var [confirmDeliveryModal, setConfirmDeliveryModal] = useState<boolean>(false);
	var [refundRequest, setRefundRequest] = useState<RefundRequest>(null);
	var [refundRequestModal, setRefundRequestModal] = useState<boolean>(false);

	var [sendingQueue, setSendingQueue] = useState<boolean>(false);
	var [shownModal, setShownModal] = useState<ModalType>(null);
	var [deleteConfirm, setDeleteConfirm] = useState<string>('');

	useEffect(() => {
		if (!date) {
			API.getDate(props.params.id, ['delivery', 'delivery', 'payment_methods', 'prices', 'sections', 'venue'], ['date_check']).then(res => {
				if (res.error) {
					Toast.error(res.message)
					return navigate(-1);
				}
				setDate(res.data);
			}).catch(err => {
				Toast.error('Hubo un error inesperado cargando el calendario. (LCL-1)');
				return navigate(-1);
			})
		}
		if (!paymentMethods) {
			API.getEventsQL(['payment_methods', 'delivery_methods', 'event_date_status']).then(res => {
				if (res.error) return Toast.error(`${res.message} (LCL-QL-2)`);
				setPaymentMethods(res.data.payment_methods);
				setDeliveryMethods(res.data.delivery_methods);
				setEventDateStatus(res.data.event_date_status)
			}).catch(err => {
				Toast.error('Hubo un error cargando los métodos de pago. (LCL-QL-1)');
			})
		}
	}, []);

	var editDate = (setLoading: SetLoading) => {
		var { valid, prompts, form } = Validator({
			date: date.date,
			published: date.published ? 1 : 0,
			active_web: date.active_web ? 1 : 0,
			active_pdv: date.active_pdv ? 1 : 0,
			status_id: date.status_id
		}, {
			date: ['number'],
		});

		setDatePrompts(prompts);
		if (!valid) return;
		setLoading(true);
		API.editDate(date.date_id, {
			date: form.date,
			published: form.published,
			active_web: form.active_web,
			active_pdv: form.active_pdv,
			status_id: form.status_id,
		}).then(res => {
			if (res.error) return setDatePrompts([res.message]);
			return Toast.success('Se ha guardado el calendario.');
		}).catch(err => {
			return setDatePrompts(['Hubo un error inesperado guardando el calendario (LCL-2)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var addSection = (ev: React.MouseEvent, data: DropdownItemProps) => {
		var sec = date.venue.sections.find(a => a.section_id == data.value);
		if (!sec) return;
		var today = moment().startOf('day');
		setModalPrompts(null);
		setShownModal(ModalType.EDIT_SECTION);
		setSelectedSection({
			section_id: sec.section_id,
			section_name: sec.section_name,
			date_start: today.unix(),
			date_end: Math.max(date.date, date.date_web_end),
			numbered: sec.numbered,
			published: true,
		})
	}

	var saveSection = (setLoading: SetLoading) => {
		if (!selectedSection) return;
		var { valid, prompts } = Validator(selectedSection, {
			section_id: ['number'],
			date_start: [
				{ rule: 'number', prompt: 'Favor de seleccionar la fecha de inicio' },
			],
			date_end: [
				{ rule: 'number', prompt: 'Favor de seleccionar la fecha fin.' },
				{ rule: () => selectedSection.date_start < selectedSection.date_end, prompt: 'La fecha fin no puede ser antes de la fecha inicial.' },
			],
		});

		setModalPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		API.addDateSection(date.date_id, selectedSection.section_id, selectedSection.date_start, selectedSection.date_end, []).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			Toast.success(`Se ha guardado la sección "${selectedSection.section_name}"`);
			var dt = { ...date };
			var sx = dt.sections.findIndex(a => a.section_id == selectedSection.section_id);
			if (sx == -1) {
				dt.sections.push({
					section_id: selectedSection.section_id,
					section_name: selectedSection.section_name,
					date_start: selectedSection.date_start,
					date_end: selectedSection.date_end,
					active: isActive(selectedSection.date_start, selectedSection.date_end),
					description: '',
					numbered: selectedSection.numbered,
					published: true,
					prices: []
				});
			} else {
				dt.sections[sx].date_start = selectedSection.date_start;
				dt.sections[sx].date_end = selectedSection.date_end;
				dt.sections[sx].active = isActive(selectedSection.date_start, selectedSection.date_end);
				dt.sections[sx].published = selectedSection.published;
			}
			setDate(dt);
			setSelectedSection(null);
		}).catch(err => {
			setModalPrompts(['Hubo un error inesperado agregando la sección.']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var editSection = (section_id: number) => {
		return () => {
			var section = date.sections.find(a => a.section_id == section_id);
			setModalPrompts(null);
			setShownModal(ModalType.EDIT_SECTION);
			setSelectedSection({
				section_id,
				section_name: section.section_name,
				numbered: section.numbered,
				date_start: section.date_start,
				date_end: section.date_end,
				published: section.published
			});
		}
	}

	var addSectionPrice = (section_id: number) => {
		return () => {
			var section = date.sections.find(a => a.section_id == section_id);
			if (!section) return;

			setEditingPrice({
				section_id,
				price_id: null,
				price_name: '',
				cost: null,
				commission: null,
				multiple: 1,
				min_tickets: 1,
				max_tickets: 10,
				date_start: section.date_start,
				date_end: section.date_end,
			})
		}
	}

	var editPrice = (section_id: number, price_id: number) => {
		return () => {
			if (!hasAccess(UserAccess.DATES.EDIT_PRICES)) return;
			var sec = date.sections.find(a => a.section_id == section_id);
			if (!sec) return;
			var price = sec.prices.find(a => a.price_id == price_id);
			if (!price) return;
			setModalPrompts(null);
			setEditingPrice({
				section_id,
				...price
			});
		}
	}

	var savePriceEdit = (setLoading: SetLoading) => {
		var { valid, prompts, form } = Validator(editingPrice, {
			commission: [
				{ rule: 'float', prompt: 'La comisión debe de ser un número.' }
			],
			cost: [
				{ rule: 'float', prompt: 'El precio del boleto no es válido.' }
			],
			price_name: [
				{ rule: 'minLength', params: [3], prompt: 'El nombre del precio debe de ser minimo 3 caracteres.' },
				{ rule: 'maxLength', params: [32], prompt: 'El nombre del precio debe de ser máximo 64 caracteres.' },
				{ rule: 'empty', prompt: 'Favor de ingresar el nombre del precio.' },
			],
			min_tickets: [
				{ rule: 'number', prompt: 'El número minimo de boletos no es válido.' },
				{ rule: 'min', params: [0], prompt: 'El minimo de boletos debe de ser minimo 0.' },
				{ rule: () => editingPrice.max_tickets > editingPrice.min_tickets, prompt: 'El máximo de boletos debe de ser menos que el minimo de boletos.' },
			],
			max_tickets: [
				{ rule: 'number', prompt: 'El número minimo de boletos no es válido.' }
			],
			multiple: [
				{ rule: 'number', prompt: 'El multiplo no es válido.' },
				{ rule: 'min', params: [1], prompt: 'El multiplo debe de ser mínimo 1.' }
			],
			date_start: [
				{ rule: 'number', prompt: 'Favor de seleccionar la fecha de inicio.' }
			],
			date_end: [
				{ rule: 'number', prompt: 'Favor de seleccionar la fecha fin.' },
				{ rule: () => editingPrice.date_start < editingPrice.date_end, prompt: 'La fecha fin no puede ser antes de la fecha inicial.' }
			],
		})
		setModalPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		if (form.price_id) {
			API.editPrice(form.price_id, form).then(res => {
				if (res.error) return setModalPrompts([res.message]);
				var dt = { ...date };
				var sx = dt.sections.findIndex(a => a.section_id == editingPrice.section_id);
				var px = dt.sections[sx].prices.findIndex(a => a.price_id == form.price_id);
				dt.sections[sx].prices[px] = {
					price_id: form.price_id,
					section_id: form.section_id,
					price_name: form.price_name,
					cost: form.cost,
					commission: form.commission,
					min_tickets: form.min_tickets,
					max_tickets: form.max_tickets,
					multiple: form.multiple,
					date_start: form.date_start,
					date_end: form.date_end,
					active: isActive(form.date_start, form.date_end),
					published_pdv: form.published_pdv,
					published_web: form.published_web,
				}
				setDate(dt);
				setEditingPrice(null);
				Toast.success(`Se ha guardado el precio "${form.price_name}"`);
			}).catch(err => {
				setModalPrompts(['Hubo un error inesperado guardando el precio. (LCL-1)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.addPrice(date.date_id, form.section_id, form).then(res => {
				if (res.error) return setModalPrompts([res.message]);
				var dt = { ...date };
				var sx = dt.sections.findIndex(a => a.section_id == editingPrice.section_id);
				dt.sections[sx].prices.push({
					price_id: res.data.price_id,
					section_id: form.section_id,
					price_name: form.price_name,
					cost: form.cost,
					commission: form.commission,
					min_tickets: form.min_tickets,
					max_tickets: form.max_tickets,
					multiple: form.multiple,
					date_start: form.date_start,
					date_end: form.date_end,
					active: isActive(form.date_start, form.date_end)
				});
				setDate(dt);
				setEditingPrice(null);
				Toast.success(`Se ha agregado el precio "${form.price_name}"`);
			}).catch(err => {
				setModalPrompts(['Hubo un error inesperado editando el precio. (LCL-2)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	var addPaymentMethod = (ev: any, data: DropdownItemProps) => {
		if (!hasAccess(UserAccess.DATES.EDIT_PAYMENT_METHODS)) return;
		var pm = date.payment_methods.find(a => a.payment_method == data.value);
		if (pm) {
			setSelectedPayment({
				edit: true,
				method_id: pm.payment_method,
				method_name: pm.method_name,
				date_start: pm.date_start,
				date_end: pm.date_end,
				force: pm.force_show,
				change_comission: (pm.date_commission_amount != null || pm.date_commission_percent != null),
				commission_amount: pm.date_commission_amount,
				commission_percent: pm.date_commission_percent === null ? null : pm.date_commission_percent * 100,
			});
		} else {
			var npm = paymentMethods.find(a => a.method_id == data.value);
			if (!npm) return;
			setSelectedPayment({
				edit: false,
				method_id: npm.method_id,
				method_name: npm.method_name_internal,
				date_start: date.date_web_start,
				date_end: Math.max(date.date, date.date_web_end),
				force: false,
				change_comission: false,
				commission_amount: null,
				commission_percent: null,
			});
		}
		setModalPrompts(null);
		setAddPaymentModal(true);
	}

	var savePaymentMethod = (setLoading: SetLoading) => {
		if (!hasAccess(UserAccess.DATES.EDIT_PAYMENT_METHODS)) return;
		if (!selectedPayment) return;
		var { valid, form, prompts } = Validator(selectedPayment, {
			method_id: [{ rule: 'number' }],
			date_start: ['number'],
			date_end: ['number'],
			...(selectedPayment.change_comission ? {
				commission_percent: [{ rule: 'min', params: [0] }],
				commission_amount: [{ rule: 'min', params: [0] }],
			} : {}),
		});

		setModalPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		selectedPayment.commission_amount = (!selectedPayment.change_comission || `${selectedPayment.commission_amount}`.trim() === '') ? null : selectedPayment.commission_amount;
		selectedPayment.commission_percent = (!selectedPayment.change_comission || `${selectedPayment.commission_percent}`.trim() === '') ? null : (selectedPayment.commission_percent / 100);

		API.addDatePaymentMethod(date.date_id, form.method_id, form.date_start, form.date_end, form.force, form.commission_amount, form.commission_percent).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha guardado el método de pago.');
			var dt = { ...date };
			dt.payment_methods = dt.payment_methods.filter(a => a.payment_method != form.method_id);
			dt.payment_methods.push({
				date_id: date.date_id,
				active: moment().isBetween(moment.unix(form.date_start), moment.unix(form.date_end)),
				payment_method: form.method_id,
				method_name: form.method_name,
				method_name_internal: form.method_name,
				force_show: form.force,
				date_start: form.date_start,
				date_end: form.date_end,
				commission_amount: date.commission_amount,
				commission_percent: date.commission_percent,
				date_commission_amount: form.commission_amount,
				date_commission_percent: form.commission_percent,
			});
			setDate(dt);
			setAddPaymentModal(false);
			setSelectedPayment(null);
		}).catch(err => {
			setModalPrompts(['Hubo un error guardando el método de pago (LCL-PM-1)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var removePaymentMethod = (setLoading: SetLoading) => {
		if (!selectedPayment) return;
		setLoading(true);
		API.removePaymentMethod(date.date_id, selectedPayment.method_id).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success(`Se ha eliminado el método de pago "${selectedPayment.method_name}"`);
			var dt = { ...date };
			dt.payment_methods = dt.payment_methods.filter(a => a.payment_method != selectedPayment.method_id);
			setDate(dt);
			setAddPaymentModal(false);
			setConfirmPaymentModal(false);
			setSelectedPayment(null);
		}).catch(err => {
			Toast.error('Hubo un error inesperado eliminando el método de pago.');
		}).catch(err => {
			setLoading(false);
		})
	}

	var addDeliveryMethod = (ev: any, data: DropdownItemProps) => {
		if (!hasAccess(UserAccess.DATES.EDIT_DELIVERY_METHODS)) return;
		var dd = date.delivery.find(a => a.delivery_id == data.value);
		if (dd) {
			setSelectedDelivery({
				edit: true,
				delivery_id: dd.delivery_id,
				method_name: dd.method_name,
				date_start: dd.date_start,
				date_end: dd.date_end,
				delivery_cost: dd.delivery_cost,
			});
		} else {
			var ndd = deliveryMethods.find(a => a.delivery_id == data.value);
			if (!ndd) return;
			setSelectedDelivery({
				edit: false,
				delivery_id: ndd.delivery_id,
				method_name: ndd.method_name,
				date_start: date.date_web_start,
				date_end: Math.max(date.date, date.date_web_end),
				delivery_cost: ndd.delivery_cost,
			});
		}
		setModalPrompts(null);
		setAddDeliveryModal(true);
	}

	var saveDeliveryMethod = (setLoading: SetLoading) => {
		if (!selectedDelivery) return;
		var { valid, prompts, form } = Validator(selectedDelivery, {
			delivery_id: ['number'],
			date_start: [
				{ rule: 'number', prompt: 'Favor de seleccionar la fecha de inicio.' }
			],
			date_end: [
				{ rule: 'number', prompt: 'Favor de seleccionar la fecha fin.' },
				{ rule: () => selectedDelivery.date_start < selectedDelivery.date_end, prompt: 'La fecha fin no puede ser antes de la fecha inicial.' }
			],
			delivery_cost: [
				{ rule: 'float', prompt: 'El costo no es válido' },
				{ rule: 'min', params: [0], prompt: 'El costo de entrega debe de ser por lo menos 0.' }
			]
		});
		setModalPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		API.addDateDelivery(date.date_id, form.delivery_id, form.delivery_cost, form.date_start, form.date_end).then(res => {
			if (res.error) return setModalPrompts([res.message]);

			var dt = { ...date };
			dt.delivery = dt.delivery.filter(a => a.delivery_id != form.delivery_id);
			var dd = deliveryMethods.find(a => a.delivery_id == form.delivery_id);
			dt.delivery.push({
				delivery_id: form.delivery_id,
				delivery_cost: parseFloat(form.delivery_cost),
				method_name: dd.method_name,
				ticket_type: dd.ticket_type,
				ticket_type_name: dd.ticket_type_name,
				needs_location: dd.needs_location,
				date_start: form.date_start,
				date_end: form.date_end,
				active: moment().isBetween(moment.unix(form.date_start), moment.unix(form.date_end))
			});
			setDate(dt);

			setAddDeliveryModal(false);
			setSelectedDelivery(null);
		}).catch(err => {
			return setModalPrompts(['Hubo un error inesperado agregando la forma de envío (LCL-1)']);
		}).finally(() => {
			setLoading(false);
		})
	}

	var removeDelivery = (setLoading: SetLoading) => {
		if (!selectedDelivery) return;
		setLoading(true);
		API.removeDateDelivery(date.date_id, selectedDelivery.delivery_id).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success(`Se ha eliminado la forma de envío "${selectedDelivery.method_name}"`);
			var dt = { ...date };
			dt.delivery = dt.delivery.filter(a => a.delivery_id != selectedDelivery.delivery_id);
			setDate(dt);

			setAddDeliveryModal(false);
			setConfirmDeliveryModal(false);
			setSelectedDelivery(null);
		}).catch(err => {
			Toast.error('Hubo un error inesperado eliminando la forma de entrega. (LCL-1)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var saveDateDates = (setLoading: SetLoading) => {
		var { valid } = Validator(date, {
			date_web_start: ['number'],
			date_web_end: ['number'],
			date_pdv_start: ['number'],
			date_pdv_end: ['number'],
			scanner_start: ['number'],
			scanner_end: ['number'],
		});
		if (!valid) return Toast.error('Una o mas fechas del calendario no es válida.');
		setLoading(true);
		API.editDate(date.date_id, {
			date_web_start: date.date_web_start,
			date_web_end: date.date_web_end,
			date_pdv_start: date.date_pdv_start,
			date_pdv_end: date.date_pdv_end,
			scanner_start: date.scanner_start,
			scanner_end: date.scanner_end,
		}).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se han guardado las fechas del calendario.');
		}).catch(err => {
			Toast.error('Hubo un error guardando las fechas del calendario. (LCL-1)')
		}).finally(() => {
			setLoading(false);
		})
	}

	var editSeatmap = (section_id: number) => {
		return () => {
			var section = date.sections.find(a => a.section_id == section_id);
			if (!section) return;
			if (!section.numbered) return Toast.error('Esta sección no es numerada.');
			setSelectedSeatmap({
				section_id,
				section_name: section.section_name,
			});
		}
	};

	var openHoldModal = (section_id: number) => {
		return () => {
			var section = date.sections.find(a => a.section_id == section_id);
			if (!section) return;
			if (section.numbered) return Toast.error('Esta sección no es genral.');
			setSelectedSection(section);
			setShownModal(ModalType.SECTION_HOLD);
		}
	};

	var showRefundRequest = () => {
		if (refundRequest && refundRequest.sent) return;
		setRefundRequest({
			sent: false,
			refundable_web: !!date.refundable_web,
			refundable_pdv: !!date.refundable_pdv,
			refundable_complete: !!date.refundable_complete,
			comments: ''
		});
		setRefundRequestModal(true);
	}

	var sendRefundableRequest = (setLoading: SetLoading) => {
		var req: RefundRequest = refundRequest ? { ...refundRequest } : {
			sent: false,
			refundable_web: false,
			refundable_pdv: false,
			refundable_complete: false,
			comments: ''
		};
		if (!req.refundable_pdv && !req.refundable_web) {
			req.refundable_complete = false;
		}

		if ((!!req.refundable_complete) == (!!date.refundable_complete) && (!!req.refundable_pdv) == (!!date.refundable_pdv) && (!!req.refundable_web) == (!!date.refundable_web)) {
			return setModalPrompts(['La configuración seleccionada ya esta activa en el calendario.']);
		}
		if (!req.comments || req.comments.length < 5) return setModalPrompts(['Favor de ingresar comentarios completos sobre la solicitud de reembolsable.']);
		setModalPrompts([]);
		setLoading(true);
		API.createRefundableRequest(date.date_id, req.refundable_web, req.refundable_pdv, req.refundable_complete, req.comments).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			Toast.success('Se ha creado la solicitud de reembolsable y se le ha enviado los cambios solicitados a un administrador con acceso.');
			setRefundRequest({
				sent: true,
				refundable_web: req.refundable_web,
				refundable_pdv: req.refundable_pdv,
				refundable_complete: req.refundable_complete,
				comments: req.comments,
			});
			setRefundRequestModal(false);
		}).catch(err => {
			return setModalPrompts(['Hubo un error inesperado creando la solicitud de reembolsable. (LCL-1)']);
		}).finally(() => {
			setLoading(false);
		});
	}

	var sendRefundOptions = (setLoading: SetLoading) => {
		var opts = {
			refundable_web: date.refundable_web,
			refundable_pdv: date.refundable_pdv,
			refundable_complete: date.refundable_complete,
		}
		if (!opts.refundable_web && !date.refundable_pdv) {
			opts.refundable_complete = false;
		}

		setLoading(true);
		API.editDateRefundOptions(date.date_id, opts.refundable_web, opts.refundable_pdv, opts.refundable_complete).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se ha cambiado la configuración de reembolso del calendario.');
		}).catch(err => {
			return Toast.error('Hubo un error inesperado cambiando la configuración (LCL-2)');
		}).finally(() => {
			setLoading(false);
		})
	}

	var deleteEventDate = (setLoading: SetLoading) => {
		var confirm_text = props.event.event_name;
		if (deleteConfirm.trim() !== confirm_text) return Toast.error(`Favor de ingresar el texto de confirmación: "${confirm_text}"`);

		setLoading(true);
		API.deleteEventDate(date.date_id).then(res => {
			if (res.error) return Toast.error(res.message);
			Toast.success('Se ha eliminado el calendario correctamente.');
			navigate(-1);
		}).catch(err => {
			Toast.error('Hubo un error inesperado eliminado el calendario (LCL-2)');
		}).finally(() => {
			setLoading(false);
			setShownModal(null);
			setDeleteConfirm(null);
		});
	}

	var onQueueChange = (val: boolean) => {
		setSendingQueue(true);
		API.setDateQueue(date.date_id, val).then(res => {
			if (res.error) return Toast.error(res.message);
			setDate({
				...date,
				queue_id: res.data.queue_id
			});
			Toast.success(`Se ha ${!val ? 'des' : ''}activado la fila de espera para este calendario`)
		}).catch(err => {
			Toast.error('Hubo un error inesperado cambiando el estatus de la fila de espera. (LCL-1)');
		}).finally(() => {
			setSendingQueue(false);
		})
	}

	var toggleAllPrices = (section_id: number)=>{
		return ()=>{
			var sp = [...shownSectionPrices];
			if(sp.indexOf(section_id)==-1){
				sp.push(section_id);
			}else{
				sp = sp.filter(a=>a!==section_id);
			}
			setShownSectionPrices(sp);
		}
	}

	if (!date) return <Header loading text="Cargando calendario" />
	var now = unix();
	var missing_sections = date.venue.sections.filter(a => date.sections.findIndex(b => b.section_id == a.section_id) == -1);
	var date_warnings = [];
	var active_payments = date.payment_methods.length > 0 ? date.payment_methods.filter(a => a.active).length : 0;
	var active_delivery = date.delivery.length > 0 ? date.delivery.filter(a => a.active).length : 0;
	var hasEditDatesAccess = hasAccess(UserAccess.DATES.CHANGE_START_END_DATES);
	if (active_payments <= 0) {
		date_warnings.push('No hay métodos de pago activos.');
	}
	if (active_delivery <= 0) {
		date_warnings.push('No hay formas de entrega activas.');
	}

	var onDateFormChange = bindFormChange(date, setDate);
	var onSectionFormChange = bindFormChange(selectedSection, setSelectedSection);
	var onPaymentFormChange = bindFormChange(selectedPayment, setSelectedPayment);
	var onDeliveryFromChange = bindFormChange(selectedDelivery, setSelectedDelivery);
	var onPriceFormChange = bindFormChange(editingPrice, setEditingPrice);
	var onRequestFormChange = bindFormChange(refundRequest, setRefundRequest);
	var now = moment().unix();

	return <div>
		{date_warnings.length > 0 && (
			<Message type="warning" text="El calendario tiene algunos problemas" list={date_warnings} style={{ maxWidth: 500, margin: 'auto', marginBottom: 15 }} />
		)}
		<Groupper title="Editar calendario" titleRight={`ID: ${date.date_id}`} width={600} actions={(
			<Button text="Guardar" color="black" onClick={editDate} />
		)}>
			<Input value={date.date} onChange={onDateFormChange('date')} calendar={{ date: date.date, mode: 'datetime' }} readonly={!hasAccess(UserAccess.DATES.CHANGE_DATE)} inputStyle={{ fontSize: 20, textAlign: 'center', fontWeight: 'bold' }} label="Fecha" />
			<Field label="Recinto" >
				<Input value={`${date.venue_name}, ${date.city}`} readonly button={(
					<Link className="fr button" to={`/venues/${date.venue_id}`} target="_blank">Ver recinto</Link>
				)} />
			</Field>
			{hasAccess(UserAccess.DATES.CHANGE_STATUS) && (
				<Field amount={3} label="Estatus">
					<Field style={{ marginTop: 5 }}>
						<Checkbox label="Publicado" checked={!!date.published} onChange={onDateFormChange('published')} />
					</Field>
					<Field style={{ marginTop: 5 }}>
						<Checkbox label="Activo Web" checked={!!date.active_web} onChange={onDateFormChange('active_web')} />
					</Field>
					<Field style={{ marginTop: 5 }}>
						<Checkbox label="Activo PDV" checked={!!date.active_pdv} onChange={onDateFormChange('active_pdv')} />
					</Field>
				</Field>
			)}
			{(!date.active_web || !date.active_pdv) && (
				<Field label="Estado no venta">
					<Dropdown selection fluid
						value={date.status_id}
						placeholder="Cambair estatus"
						selectOnBlur={false}
						selectOnNavigation={false}
						loading={!eventDateStatus}
						onChange={onDateFormChange('status_id', true)}
						options={eventDateStatus ? eventDateStatus.filter(a => a.status_id !== 1).map(a => ({
							value: a.status_id,
							text: a.status_name,
							description: `ID: ${a.status_id}`,
						})) : []}
					/>
				</Field>
			)}
			<Message list={datePrompts} type="error" />
		</Groupper>
		{/*===============================
			SECTIONS
			=============================== */}
		<Groupper width={600} style={{ marginTop: 15 }} fitted title="Secciones">
			{loadingSections ? (
				<Header loading text="Cargando secciones" size="small" />
			) : <>
				{hasAccess(UserAccess.DATES.EDIT_SECTIONS) && (
					<div className="section">
						<Dropdown selection fluid placeholder="Agrega una sección" value={null} selectOnBlur={false} selectOnNavigation={false} options={missing_sections.map(a => ({
							value: a.section_id,
							text: a.section_name,
							description: `ID: ${a.section_id}`,
							onClick: addSection,
							icon: (a.numbered ? 'hashtag' : 'ticket')
						}))} />
					</div>
				)}
				{date.sections.length == 0 && (
					<table className="fr table">
						<tbody>
							<tr>
								<td className="empty">No hay secciones agregadas</td>
							</tr>
						</tbody>
					</table>
				)}
				{date.sections.map(a => {
					var active = a.active && a.prices.length > 0 && a.published;
					var date_active = (a.date_start < now && a.date_end > now);
					var showing_all = shownSectionPrices.indexOf(a.section_id)>-1;
					var shown_prices = a.prices.filter(pr => showing_all || ((pr.published_web || pr.published_pdv) && pr.active));

					return (
						<table className={classNames('fr table', {
							selectable: hasAccess(UserAccess.DATES.EDIT_PRICES),
						})} key={`date-section-${a.section_id}`}>
							<thead>
								<tr id="sectionRow-<%= i.section_id %>">
									<th colSpan={6} className="title">
										<div className="fr flex">
											<div className="grow">
												<div className={`fr ${active ? 'green' : ''} label`} style={{ marginRight: 5, display: 'flex', alignItems: 'center' }}>
													<Icon name={active ? 'check' : 'times'} style={{ marginRight: 0, fontSize: 16 }} />
													{!active ? (
														<Icon name={classNames({
															'dollar sign': a.prices.length == 0,
															calendar: a.prices.length > 0 && !date_active,
															'eye slash': date_active && a.prices.length > 0 && !a.published
														}) as any} style={{ marginRight: 0, fontSize: 10 }} />
													) : null}
												</div>
												{a.section_name}
											</div>
											<Dropdown icon={null} direction="left" trigger={(
												<Button iconName="wrench" size="tiny" text="Opciones" />
											)}>
												<Dropdown.Menu>
													{hasAccess(UserAccess.DATES.EDIT_PRICES) && (
														<Dropdown.Item icon='plus' text='Agregar Precio' onClick={addSectionPrice(a.section_id)} />
													)}
													<Dropdown.Item icon={showing_all ? 'eye slash' : 'eye'} text={showing_all ? 'Esconder inactivos' : 'Mostrar inactivos'} onClick={toggleAllPrices(a.section_id)} />
													{hasAccess(UserAccess.DATES.EDIT_SECTIONS) && <>
														{a.numbered ? (
															<Dropdown.Item text="Mapa" icon="map" onClick={editSeatmap(a.section_id)} />
														) : (
															<Dropdown.Item text="Bloqueo" icon="bookmark" onClick={openHoldModal(a.section_id)} />
														)}
														<Dropdown.Item text="Editar" icon="edit" onClick={editSection(a.section_id)} />
													</>}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</th>
								</tr>
								<tr>
									<th className="collapsing" style={{ textAlign: 'center' }}>ID</th>
									<th>Nombre</th>
									<th className="collapsing" style={{ textAlign: 'center' }}>Precio</th>
									<th className="collapsing" style={{ textAlign: 'center' }}>Comisión</th>
									<th className="collapsing">Activo PDV</th>
									<th className="collapsing">Activo Web</th>
								</tr>
							</thead>
							<tbody>
								{shown_prices.length == 0 ? (
									<tr className="noselect">
										<td className="empty" colSpan={6}>
											No hay precios en esta sección
											{shown_prices.length!==a.prices.length && <>
												<br />
												Hay <b>{a.prices.length-shown_prices.length}</b> precio{a.prices.length-shown_prices.length==1 ? '' : 's'} no activo{a.prices.length-shown_prices.length==1 ? '' : 's'}
											</>}
										</td>
									</tr>
								) : null}
								{shown_prices.map(p => {
									const active_price_pdv = p.published_pdv && p.active;
									const active_price_web = p.published_web && p.active;
									return (<tr key={`section-price-${p.price_id}`} onClick={editPrice(a.section_id, p.price_id)}>
										<td>{p.price_id}</td>
										<td>{p.price_name}</td>
										<td>${addCommas(p.cost)}</td>
										<td style={{ textAlign: 'center' }}>${addCommas(p.commission, true, true)}</td>
										<td style={{ textAlign: 'center' }}>
											<Icon name={active_price_pdv ? 'check' : 'remove'} color={active_price_pdv ? 'green' : 'black'} />
										</td>
										<td style={{ textAlign: 'center' }}>
											<Icon name={active_price_web ? 'check' : 'remove'} color={active_price_web ? 'green' : 'black'} />
										</td>
									</tr>)
								})}
							</tbody>
						</table>
					)
				})}
			</>}
		</Groupper>
		{hasAccess(UserAccess.DATES.EDIT_SECTIONS) && (
			<Modal size="tiny" open={!!selectedSection && shownModal === ModalType.EDIT_SECTION} onClose={bindClose(setSelectedSection)}>
				<Modal.Header>Agregar sección</Modal.Header>
				{selectedSection ? <Modal.Content>
					<Field amount={2}>
						<Input label="Sección" value={selectedSection.section_name} readonly />
						<Input label="ID Sección" value={selectedSection.section_id} readonly />
					</Field>
					<div style={{ textAlign: 'center', margin: '10px 0 5px 0' }}>
						<Checkbox checked={selectedSection.published} onChange={onSectionFormChange('published')} label={'Publicada'} />
					</div>
					<Input label={'Fecha inicio'} value={selectedSection.date_start} calendar={{ date: selectedSection.date_start, mode: 'datetime' }} onChange={onSectionFormChange('date_start')} type={now > selectedSection.date_start ? 'normal' : 'warning'} />
					<Input label={'Fecha fin'} value={selectedSection.date_end} calendar={{ date: selectedSection.date_end, mode: 'datetime' }} onChange={onSectionFormChange('date_end')} type={now < selectedSection.date_end ? 'normal' : 'warning'} />
					<Message list={modalPrompts} type="error" style={{ marginTop: 10 }} />
				</Modal.Content> : null}
				<Modal.Actions>
					<Button text="Regresar" basic onClick={() => setSelectedSection(null)} />
					<Button text="Guardar" color="black" onClick={saveSection} />
				</Modal.Actions>
			</Modal>
		)}
		{hasAccess(UserAccess.DATES.EDIT_PRICES) && (
			<Modal open={!!editingPrice} onClose={bindClose(setEditingPrice)} size="tiny">
				<Modal.Header>{editingPrice && editingPrice.price_id ? 'Editar' : 'Agregar'} precio</Modal.Header>
				{editingPrice ? (
					<Modal.Content>
						<Field amount={editingPrice.price_id ? 2 : 1}>
							{editingPrice.price_id ? <Input label="ID Precio" value={editingPrice.price_id} readonly /> : null}
							<Input label="Nombre" value={editingPrice.price_name} onChange={onPriceFormChange('price_name')} />
						</Field>
						<Field amount={2}>
							<Input label="Precio" value={editingPrice.cost} onChange={onPriceFormChange('cost')} />
							<Input label="Comisión" value={editingPrice.commission} onChange={onPriceFormChange('commission')} />
						</Field>

						<div className="ui divider"></div>
						<Field amount={3}>
							<Input label="Minimo boletos" value={editingPrice.min_tickets} onChange={onPriceFormChange('min_tickets')} />
							<Input label="Máximo boletos" value={editingPrice.max_tickets} onChange={onPriceFormChange('max_tickets')} />
							<Input label="Múltiplo" value={editingPrice.multiple} onChange={onPriceFormChange('multiple')} />
						</Field>
						<Field amount={2}>
							<Input label="Fecha inicio" value={editingPrice.date_start} onChange={onPriceFormChange('date_start')} calendar={{ date: editingPrice.date_start }} type={(!editingPrice.price_id || now > editingPrice.date_start) ? 'normal' : 'warning'} />
							<Input label="Fecha fin" value={editingPrice.date_end} onChange={onPriceFormChange('date_end')} calendar={{ date: editingPrice.date_end }} type={(!editingPrice.price_id || now < editingPrice.date_end) ? 'normal' : 'warning'} />
						</Field>
						<Field amount={2}>
							<Checkbox className="field" label={'Publicar PDV'} onChange={onPriceFormChange('published_pdv')} checked={!!editingPrice.published_pdv} />
							<Checkbox className="field" label={'Publicar WEB'} onChange={onPriceFormChange('published_web')} checked={!!editingPrice.published_web} />
						</Field>
						<Message list={modalPrompts} type="error" />
					</Modal.Content>
				) : null}
				<Modal.Actions>
					<Button text="Regresar" basic onClick={() => setEditingPrice(null)} />
					<Button text="Guardar" color="black" onClick={savePriceEdit} />
				</Modal.Actions>
			</Modal>
		)}
		{/*===============================
			PAYMENT METHODS
			=============================== */}
		<Groupper title="Métodos de pago" fitted width={600} style={{ marginTop: 15 }}>
			{hasAccess(UserAccess.DATES.EDIT_PAYMENT_METHODS) && (
				<div className="section">
					<Dropdown selection fluid
						value={null}
						placeholder="Agrega un método de pago"
						selectOnBlur={false}
						selectOnNavigation={false}
						loading={!paymentMethods}
						options={paymentMethods ? paymentMethods.map(a => ({
							value: a.method_id,
							text: a.method_name_internal,
							description: `ID: ${a.method_id}`,
							onClick: addPaymentMethod,
						})) : []}
					/>
				</div>
			)}
			<Table striped 
				selectable={hasAccess(UserAccess.DATES.EDIT_PAYMENT_METHODS)}
				headers={['ID', 'Método', 'Comisión', 'Forzar', 'Activo']}
				centeredIndexes={[2, 3, 4]}
			>
				{date.payment_methods.sort((a, b) => a.payment_method - b.payment_method).map(a=>(
					<Table.Row
						collapsingIndexes={[2,3,4]}
						centeredIndexes={[2,3,4]}
						data={[
							a.payment_method,
							a.method_name_internal,
							((a.date_commission_amount >= 0 && a.date_commission_amount !== null) || (a.date_commission_percent >= 0 && a.date_commission_percent !== null)) ? <>
								<Icon name="pencil" />
								{formatCommission(a.date_commission_percent * 100, a.date_commission_amount)}
							</> : ((a.commission_amount >= 0 && a.commission_amount !== null) || (a.commission_percent >= 0 && a.commission_percent !== null)) ? (
								formatCommission(a.commission_percent * 100, a.commission_amount)
							) : (
								<Icon name="minus" color="grey" />
							),
							<Icon name={a.force_show ? 'check' : 'minus'} color={a.force_show ? 'black' : 'grey'} />,
							<Icon name={a.active ? 'check' : 'minus'} color={a.active ? 'green' : 'grey'} />,
						]}
					/>
				))}
				{date.payment_methods.length == 0 && (
					<Table.Cell row>No hay métodos de pago agregados</Table.Cell>
				)}
			</Table>
		</Groupper>
		{hasAccess(UserAccess.DATES.EDIT_PAYMENT_METHODS) && (
			<Modal size={'tiny'} open={addPaymentModal} onClose={() => setAddPaymentModal(false)}>
				<Modal.Header>Método de pago</Modal.Header>
				{selectedPayment && (
					<Modal.Content>
						<Input label="Método de pago" value={selectedPayment.method_name} readonly />
						<Input label="Fecha inicio" value={selectedPayment.date_start} onChange={onPaymentFormChange('date_start')} calendar={{ date: selectedPayment.date_start }} type={now > selectedPayment.date_start ? 'normal' : 'warning'} />
						<Input label="Fecha fin" value={selectedPayment.date_end} onChange={onPaymentFormChange('date_end')} calendar={{ date: selectedPayment.date_end }} type={now < selectedPayment.date_end ? 'normal' : 'warning'} />
						<Field label="Forzar método de pago">
							<Checkbox label="Mostrar método de pago sin importar el día de la semana" checked={!!selectedPayment.force} onChange={onPaymentFormChange('force')} />
						</Field>
						<Field label="Comisión calendario">
							<Checkbox label="Cambiar la comision solo para este calendario" checked={!!selectedPayment.change_comission} onChange={onPaymentFormChange('change_comission')} />
						</Field>
						{
							selectedPayment.change_comission && <>
								<Field amount={2} >
									<Input label="Comision cantidad" value={selectedPayment.commission_amount} onChange={onPaymentFormChange('commission_amount')} inputType="number" />
									<Input label="Comision porcentaje" value={selectedPayment.commission_percent} onChange={onPaymentFormChange('commission_percent')} inputType="number" />
								</Field>
								{(selectedPayment.commission_percent > 0 || selectedPayment.commission_amount > 0) && (
									<Message centered type="info">
										Se agregará {formatCommission(selectedPayment.commission_percent, selectedPayment.commission_amount)}
									</Message>
								)}
							</>
						}
						<Message list={modalPrompts} type="error" />
						{selectedPayment.edit && (
							<Button basic text="Eliminar" color="red" style={{ width: 200, margin: 'auto', display: 'block' }} onClick={() => setConfirmPaymentModal(true)} />
						)}
					</Modal.Content>
				)}
				<Modal.Actions>
					<Button text="Regresar" basic onClick={() => setAddPaymentModal(false)} />
					<Button text="Guardar" color="black" onClick={savePaymentMethod} />
				</Modal.Actions>
			</Modal>
		)}
		<Modal size="mini" open={confirmPaymentModal} onClose={() => setConfirmPaymentModal(false)}>
			<Modal.Header>Eliminar método de pago</Modal.Header>
			{selectedPayment && (
				<Modal.Content>
					<Header size="small" text="¿Eliminar método de pago?" subtext={selectedPayment.method_name} />
				</Modal.Content>
			)}
			<Modal.Actions>
				<Button text="Regresar" basic onClick={() => setConfirmPaymentModal(false)} />
				<Button text="Eliminar" color='red' onClick={removePaymentMethod} />
			</Modal.Actions>
		</Modal>

		{/*===============================
			DELIVERY METHODS
			=============================== */}
		<Groupper title="Formas de entrega" width={600} fitted style={{ marginTop: 15 }}>
			{hasAccess(UserAccess.DATES.EDIT_DELIVERY_METHODS) && (
				<div className="section">
					<Dropdown selection fluid
						placeholder="Agrega un método de pago"
						value={null}
						selectOnBlur={false}
						selectOnNavigation={false}
						loading={!deliveryMethods}
						options={deliveryMethods ? deliveryMethods.map(a => ({
							value: a.delivery_id,
							text: a.method_name,
							icon: (a.needs_location ? 'truck' : (a.ticket_type == TicketType.PHYSICAL ? 'ticket' : (a.ticket_type == TicketType.DIGITAL ? 'mail' : 'question'))),
							description: `ID: ${a.delivery_id}`,
							onClick: addDeliveryMethod,
						})) : []}
					/>
				</div>
			)}
			<table className={`fr striped table ${hasAccess(UserAccess.DATES.EDIT_DELIVERY_METHODS) ? 'selectable' : ''}`}>
				<thead>
					<tr>
						<th className="collapsing">ID</th>
						<th>Método</th>
						<th className="collapsing">Costo</th>
						<th className="collapsing">Domicilio</th>
						<th className="collapsing">Activo</th>
					</tr>
				</thead>
				<tbody>
					{date.delivery.map(a => (
						<tr key={`date-dm-${a.delivery_id}`} onClick={e => addDeliveryMethod(e, { value: a.delivery_id })}>
							<td className="collapsing">{a.delivery_id}</td>
							<td>{a.method_name}</td>
							<td className="collapsing">${addCommas(a.delivery_cost)}</td>
							<td className="collapsing">
								<Icon name={a.needs_location ? 'check' : 'minus'} color={a.needs_location ? 'black' : 'grey'} />
							</td>
							<td className="collapsing">
								<Icon name={a.active ? 'check' : 'remove'} color={a.active ? 'green' : 'grey'} />
							</td>
						</tr>
					))}
					{date.delivery.length == 0 && (
						<tr>
							<td className="empty" colSpan={5}>No hay formas de envío agregadas</td>
						</tr>
					)}
				</tbody>
			</table>
		</Groupper>
		{hasAccess(UserAccess.DATES.EDIT_DELIVERY_METHODS) && (
			<Modal open={addDeliveryModal} onClose={() => setAddDeliveryModal(false)} size="tiny">
				<Modal.Header>Forma de entrega</Modal.Header>
				{selectedDelivery && <Modal.Content>
					<Input label="Forma de entrega" value={selectedDelivery.method_name} readonly />
					<Input label="Fecha inicio" value={selectedDelivery.date_start} onChange={onDeliveryFromChange('date_start')} calendar={{ date: selectedDelivery.date_start }} />
					<Input label="Fecha fin" value={selectedDelivery.date_end} onChange={onDeliveryFromChange('date_end')} calendar={{ date: selectedDelivery.date_end }} />
					<Input label="Costo" value={selectedDelivery.delivery_cost} onChange={onDeliveryFromChange('delivery_cost')} />
					{selectedDelivery.edit && (
						<Button basic text="Eliminar" color="red" style={{ width: 200, margin: 'auto', display: 'block' }} onClick={() => setConfirmDeliveryModal(true)} />
					)}
					<Message list={modalPrompts} type="error" style={{ marginTop: 10 }} />
				</Modal.Content>}
				<Modal.Actions>
					<Button text="Regresar" basic onClick={() => setAddDeliveryModal(false)} />
					<Button text="Guardar" color="black" onClick={saveDeliveryMethod} />
				</Modal.Actions>
			</Modal>
		)}
		<Modal size="mini" open={confirmDeliveryModal} onClose={() => setConfirmDeliveryModal(false)}>
			<Modal.Header>Eliminar método de pago</Modal.Header>
			{selectedDelivery && (
				<Modal.Content>
					<Header size="small" text="¿Eliminar forma de entrega?" subtext={selectedDelivery.method_name} />
				</Modal.Content>
			)}
			<Modal.Actions>
				<Button text="Regresar" basic onClick={() => setConfirmDeliveryModal(false)} />
				<Button text="Eliminar" color='red' onClick={removeDelivery} />
			</Modal.Actions>
		</Modal>

		<Groupper title="Fechas" width={600} style={{ marginTop: 15 }} actions={(
			(hasEditDatesAccess && <Button text="Guardar" color="black" onClick={saveDateDates} />)
		)}>
			<Field amount={2}>
				<Input label="Fecha inicio WEB" value={date.date_web_start} calendar={{ date: date.date_web_start }} onChange={onDateFormChange('date_web_start')} type={date.date_web_start > now ? 'warning' : 'normal'} readonly={!hasEditDatesAccess} />
				<Input label="Fecha fin WEB" value={date.date_web_end} calendar={{ date: date.date_web_end }} onChange={onDateFormChange('date_web_end')} type={date.date_web_end < now ? 'warning' : 'normal'} readonly={!hasEditDatesAccess} />
			</Field>
			<Field amount={2}>
				<Input label="Fecha inicio PDV" value={date.date_pdv_start} calendar={{ date: date.date_pdv_start }} onChange={onDateFormChange('date_pdv_start')} type={date.date_pdv_start > now ? 'warning' : 'normal'} readonly={!hasEditDatesAccess} />
				<Input label="Fecha fin PDV" value={date.date_pdv_end} calendar={{ date: date.date_pdv_end }} onChange={onDateFormChange('date_pdv_end')} type={date.date_pdv_end < now ? 'warning' : 'normal'} readonly={!hasEditDatesAccess} />
			</Field>
			<Field amount={2}>
				<Input label="Fecha inicio checadores" value={date.scanner_start} calendar={{ date: date.scanner_start }} onChange={onDateFormChange('scanner_start')} type={date.scanner_start > now ? 'warning' : 'normal'} readonly={!hasEditDatesAccess} />
				<Input label="Fecha fin checadores" value={date.scanner_end} calendar={{ date: date.scanner_end }} onChange={onDateFormChange('scanner_end')} type={date.scanner_end < now ? 'warning' : 'normal'} readonly={!hasEditDatesAccess} />
			</Field>
		</Groupper>
		<Groupper style={{ marginTop: 15 }} width={600} title="Otra configuración" actions={hasAccess([UserAccess.DATES.SET_REFUNDABLE, UserAccess.DATES.SET_REFUNDABLE_COMPLETE], true) && (
			<Button text="Guardar" color="black" onClick={sendRefundOptions} />
		)}>
			<div className="fr option">
				<div className="info">
					<div className="title">Fila de espera</div>
					<div className="description">Si el evento tendrá mucho tráfico, se recomienda agregar una fila de espera.</div>
				</div>
				{sendingQueue ? (
					<Loader active inline size="small" style={{ marginRight: 16 }} />
				) : hasAccess(!!date.queue_id ? UserAccess.DATES.REMOVE_QUEUE : UserAccess.DATES.ADD_QUEUE) ? (
					<Checkbox checked={!!date.queue_id} onChange={onQueueChange} />
				) : (
					<Icon name={!!date.queue_id ? 'check-circle' : "times-circle"} color={!!date.queue_id ? 'green' : 'black'} />
				)}
			</div>
			{!hasAccess([UserAccess.DATES.SET_REFUNDABLE, UserAccess.DATES.SET_REFUNDABLE_COMPLETE], true) && (
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable</div>
						<div className="description">Solicita un cambio en la configuración de reembolsos del calendario.</div>
					</div>
					<Button text={refundRequest?.sent ? 'Solicitada' : 'Solicitar'} color={refundRequest?.sent ? null : "black"} iconName={refundRequest?.sent ? "check-circle" : null} onClick={showRefundRequest} />
				</div>
			)}
			{hasAccess(UserAccess.DATES.SET_REFUNDABLE) && <>
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable Web</div>
						<div className="description">Permitir solicitudes de reembolso de compras web para este calendario.</div>
					</div>
					<Checkbox checked={!!date.refundable_web} onChange={onDateFormChange('refundable_web')} />
				</div>
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable PDV</div>
						<div className="description">Permitir solicitudes de reembolso de compras PDV para este calendario.</div>
					</div>
					<Checkbox checked={!!date.refundable_pdv} onChange={onDateFormChange('refundable_pdv')} />
				</div>
			</>}
			{!hasAccess(UserAccess.DATES.SET_REFUNDABLE) && <>
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable Web</div>
						<div className="description">Permitir solicitudes de reembolso de compras web para este calendario.</div>
					</div>
					<Icon name={!!date.refundable_web ? 'check-circle' : "times-circle"} color={!!date.refundable_web ? 'green' : 'black'} />
				</div>
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable PDV</div>
						<div className="description">Permitir solicitudes de reembolso de compras PDV para este calendario.</div>
					</div>
					<Icon name={!!date.refundable_pdv ? 'check-circle' : "times-circle"} color={!!date.refundable_pdv ? 'green' : 'black'} />
				</div>
			</>}
			{!hasAccess(UserAccess.DATES.SET_REFUNDABLE_COMPLETE) ? (
				<div className="fr option last">
					<div className="info">
						<div className="title">Reembolsable Completo</div>
						<div className="description">Reembolsar toda la compra <b>INCLUYENDO cargos por servicio, entrega y forma de pago</b>.</div>
					</div>
					<Icon name={!!date.refundable_complete ? 'check-circle' : "times-circle"} color={!!date.refundable_complete ? 'green' : 'black'} />
				</div>
			) : (
				<div className="fr option last">
					<div className="info">
						<div className="title">Reembolsable Completo</div>
						<div className="description">Reembolsar toda la compra <b>INCLUYENDO cargos por servicio, entrega y forma de pago</b>.</div>
					</div>
					<Checkbox checked={!!date.refundable_complete} onChange={onDateFormChange('refundable_complete')} />
				</div>
			)}
		</Groupper>

		<UserLog user={{ ...date, admin_creator_name: date.admin_creator }} style={{ maxWidth: 600, margin: 'auto', marginTop: 15 }} />

		{hasAccess(UserAccess.DATES.DELETE_DATE) && (
			<div className="fr option last" style={{ justifyContent: 'center', marginTop: 20 }}>
				<Button color="red" text="Eliminar calendario" basic onClick={() => setShownModal(ModalType.DELETE_EVENTDATE)} />
			</div>
		)}

		{/*===============================
			REEMBOLSABLE
		================================== */}
		<Modal size="tiny" open={refundRequestModal} onClose={bindClose(setRefundRequestModal)}>
			<Modal.Header>Solicitar calendario reembolsable</Modal.Header>
			<Modal.Content>
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable Web</div>
						<div className="description">Permitir solicitudes de reembolso de compras web para este calendario.</div>
					</div>
					<Checkbox checked={refundRequest?.refundable_web} onChange={onRequestFormChange('refundable_web')} />
				</div>
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable PDV</div>
						<div className="description">Permitir solicitudes de reembolso de compras pdv para este calendario.</div>
					</div>
					<Checkbox checked={refundRequest?.refundable_pdv} onChange={onRequestFormChange('refundable_pdv')} />
				</div>
				{refundRequest && (refundRequest.refundable_web || refundRequest.refundable_pdv) && hasAccess(UserAccess.DATES.REQUEST_REFUNDABLE_COMPLETE) ? (
					<div className="fr option">
						<div className="info">
							<div className="title">Reembolsable Completo</div>
							<div className="description">Reembolsar toda la compra <b>INCLUYENDO cargos por servicio, entrega y forma de pago</b>.</div>
						</div>
						<Checkbox checked={refundRequest?.refundable_complete} onChange={onRequestFormChange('refundable_complete')} />
					</div>
				) : null}
				<Field label="Comentarios" style={{ marginBottom: 15, marginTop: 0 }}>
					<textarea placeholder="Explica la razón por la cual se está haciendo la solicitud de reembolso" value={refundRequest?.comments} onChange={onRequestFormChange('comments', false, true)} rows={5}></textarea>
				</Field>
				<Message>
					Se le enviará un correo a los administradores con acceso para marcar calendarios como reembolsable con la información ingresada en este formulario.
				</Message>
				<Message list={modalPrompts} style={{ marginTop: 5 }} type="error" />
			</Modal.Content>
			<Modal.Actions>
				<Button text="Cerrar" basic onClick={bindClose(setRefundRequestModal)} />
				<Button text="Enviar" color="black" onClick={sendRefundableRequest} />
			</Modal.Actions>
		</Modal>

		{/*===============================
			SEATMAP
		================================== */}
		<Modal size="fullscreen" open={!!selectedSeatmap} onClose={() => setSelectedSeatmap(null)}>
			<Modal.Header>
				Butacas: {selectedSeatmap ? selectedSeatmap.section_name : null}
			</Modal.Header>
			{selectedSeatmap ? <Modal.Content style={{ padding: 0 }}>
				<Seatmap dateId={date.date_id} sectionId={selectedSeatmap.section_id} />
			</Modal.Content> : null}
			<Modal.Actions>
				<Button text="Regresar" basic onClick={() => setSelectedSeatmap(null)} />
			</Modal.Actions>
		</Modal>

		{/*===============================
			HOLD SEATS
		================================== */}
		<SectionHoldModal
			open={!!selectedSection && shownModal === ModalType.SECTION_HOLD}
			onClose={() => {
				setShownModal(null)
				setSelectedSection(null);
			}}
			title='Reservar Butacas'
			dateId={date.date_id}
			sectionId={selectedSection?.section_id}
			infinite={selectedSection?.infinite}
		/>

		{/*===============================
			DELETE CALENDAR
		================================== */}

		<Modal open={shownModal === ModalType.DELETE_EVENTDATE} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Eliminar Calendario</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Eliminar el calendario?' />
				<ul style={{ paddingLeft: 15 }}>
					<li>Una vez eliminada la fecha, no podrá ser recuperada</li>
					<li>Si hay boletos vendidos en el calendario <b>NO</b> será eliminado</li>
				</ul>
				<Field label='Confirmación' comment={'Ingresa el siguiente texto para eliminar el calendario'}>
					<Input
						placeholder={props.event.event_name}
						comment={`"${props.event.event_name}"`}
						commentStyle={{ fontWeight: 'bold' }}
						value={deleteConfirm}
						onChange={setDeleteConfirm}
					/>
				</Field>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				<Button text='Eliminar' color={'red'} onClick={deleteEventDate} />
			</Modal.Actions>
		</Modal>
	</div>;
}

export default EventDateComponent;