import React, { useEffect, useState } from 'react';
import { Checkbox, Modal, } from 'semantic-ui-react';
import { Button, Field, Table, Header } from 'react-frontier';
import { SetLoading } from '@arema/components/Classes';
import { useUser } from '../AdminHooks';
import UserAccess from '../UserAccess';
import API from '../API';
import Toast from 'react-hot-toast';

interface UserExternalAcccessModalProps {
	open: boolean,
	onClose: () => void,
	title?: string,
	event_id: number,
	external_id: number,
}

interface AccessCategory {
	access_id: number,
	access_name: string,
	restricted: boolean
}

var UserExternalAcccessModal = (props: UserExternalAcccessModalProps) => {
	var { hasAccess } = useUser();
	var [error, setError] = useState<string>(null);
	var [allAccess, setAllAccess] = useState<AccessCategory[]>(null);
	var [access, setAccess] = useState<number[]>(null);
	var [eventInfo, setEventInfo] = useState<{
		event_name: string,
		promoter_name: string,
		event_id: number
	}>(null);

	useEffect(() => {
		API.getUserExternalAccess(props.external_id, props.event_id, true, true).then(res => {
			if (res.error) return setError(res.message);
			setAccess(res.data.access);
			setAllAccess(res.data.all_access);
			setEventInfo(res.data.event);
		}).catch(err => {
			return setError('Hubo un error ineseprado cargando el usuario.');
		})
	}, []);

	var onAccessChange = (access_id: number) => {
		return (_: any, data: { checked?: boolean }) => {
			var acc = [...access];
			acc = acc.filter(a => a != access_id);
			if (data.checked) {
				acc.push(access_id);
			}
			setAccess(acc);
		}
	}

	var saveAccess = (setLoading: SetLoading)=>{
		if(!hasAccess(UserAccess.USERS.EDIT_ACCESS)) return;
		setLoading(true);
		API.saveUserExternalEventAccess(props.external_id, props.event_id, access).then(res=>{
			if(res.error) return Toast.error(res.message);
			Toast.success('Se han editado los permisos del usuario.');
		}).catch(err=>{
			Toast.error('Hubo un error inesperado editando los permisos del usuario (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		})
	}

	var is_dev = hasAccess(UserAccess.ADMIN.DEVELOPER);

	return (
		<Modal open={props.open} onClose={props.onClose} size='tiny' >
			<Modal.Header>{props.title}</Modal.Header>
			<Modal.Content>
				{error ? (
					<Header text='Error' subtext={error} iconName='face-frown-open' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
				) : !allAccess ? (
					<Header loading text={'Cargando registros'} containerStyle={{ marginBottom: 50, marginTop: 50 }} />
				) : <>
					<Table details striped title='Información' titleSize='small' style={{ marginBottom: 15 }} >
						<Table.Row data={['ID', eventInfo.event_id]} />
						<Table.Row data={['Evento', eventInfo.event_name]} />
						<Table.Row data={['Promotor', eventInfo.promoter_name]} />
					</Table>
					{allAccess.map((a, i) => (
						<div key={`acc-${a.access_id}`} style={{ marginBottom: 0 }}>
							<Field>
								<Checkbox toggle checked={access.indexOf(a.access_id) != -1} onChange={onAccessChange(a.access_id)} label={<label>
									{is_dev && (
										<span style={{ color: 'gray', fontSize: 12, marginRight: 5 }}>[{a.access_id}]</span>
									)}
									<span style={{ color: a.restricted ? 'brown' : 'black' }}>{(a.access_name)}</span>
								</label>} />
							</Field>
						</div>
					))}
				</>}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={props.onClose} />
				<Button text='Guardar' color='black' onClick={saveAccess} />
			</Modal.Actions>
		</Modal>
	)
}

export default UserExternalAcccessModal;